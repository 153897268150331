/**
 * #element    video
 * 
 * #extends
 *  block
 * 
 * #props
 *  url         video url
 *  videoType   default webM
 *  width
 *  height
 *  videoStyle
 *  loop        default false
 *  autoplay    default false
 *  controls    default false
 */
import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

export const ContentVideo = (props) => {
  const { url, videoType, width, height, videoStyle, loop, autoplay, controls } = props;

  const videoProps = {};

  if (autoplay === 'true') {
    videoProps.autoPlay = true;
    videoProps.muted = true;
  }

  if (loop === 'true') {
    videoProps.loop = true;
  }

  if (controls === 'true') {
    videoProps.controls = true;
  }

  return (
    <Block
      {...props}
      customStyle={{
        lineHeight: 0,
        ...(props.customStyle || {})
      }}
    >
      <video
        style={{
          backgroundColor: '#000',
          width: width,
          height: height,
          maxWidth: '100%',
          maxHeight: '100%',
          ...videoStyle
        }}
        {...videoProps}
      >
        <source
          src={extractVariable(url)}
          type={extractVariable(videoType) || 'video/webm'}
        ></source>
      </video>
    </Block>
  );
};
