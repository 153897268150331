import { callApi } from '../../../config';
import { extractVariable } from '../../helpers/extract-variable';

export const handleDebug = async (context, params) => {
  const value = extractVariable.bind(context)(params['value']);
  
  if (typeof window === 'undefined') {
    return;
  }

  alert(value);

  return true;
};
