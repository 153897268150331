import React from 'react';
import styled from 'styled-components';
import { Block, parseStyleProps } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { getBranchHTML } from '../../core/get-branch-html';

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(-50%);
`;

export const ContentAdvancedBackgroundBlock = (props) => {
  const wrapperStyle = parseStyleProps({
    cssBackgroundType: props.cssBackgroundType,
    cssBackgroundColor: props.cssBackgroundColor,
    cssBackgroundImage: props.cssBackgroundImage,
    cssBackgroundRepeat: props.cssBackgroundRepeat,
    cssBackgroundPosition: props.cssBackgroundPosition,
    cssBackgroundFit: props.cssBackgroundFit,
    cssBackgroundColorB: props.cssBackgroundColorB,
    cssBackgroundGradientDirection: props.cssBackgroundGradientDirection,
  });
  const {
    content,
    _customChildrenProps,
    _library,
    _behaviours,
    __key
  } = props;
  const extractedContent = getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);

  return (
    <Block
      {...{
        ...props,
        cssWidth: undefined,
        cssWidthHover: undefined,
        cssWidthActive: undefined,
        size: 24,
        sizeMobile: 24,
        sizeTablet: 24,
        cssContentOverflow: false,
        cssContentOverflowHover: false,
        cssContentOverflowActive: false
      }}
    >
      <BackgroundWrapper style={wrapperStyle} />
      {extractedContent}
    </Block>
  );
};