import { extractVariable } from './extract-variable';

export const extractVariableUnit = (variable) => {
  const value = extractVariable(variable);

  if (!value || typeof value !== 'string') {
    return '';
  }

  if (value.substr(-2) === 'px') {
    return 'px';
  } else if (value.substr(-1) === '%') {
    return '%';
  } else {
    return '';
  }
};
