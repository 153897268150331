import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

export class ContentTimer extends React.Component {
  state = {
    timestamp: Date.now(),
    systemOffset: (new Date()).getTimezoneOffset()
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({
        timestamp: Date.now(),
        systemOffset: (new Date()).getTimezoneOffset()
      });
    }, 500);
  }

  getFormattedTime(value, leadingZero = true) {
    if (leadingZero) {
      return `00${value}`.substr(-2);
    }

    return value;
  }

  getFormattedDate(year, month, day) {
    const {
      dateFormat = 'yyyy.mm.dd'
    } = this.props;

    if (dateFormat === 'yyyy.dd.mm') {
      return `${year}.${day}.${month}`;
    }

    return `${year}.${month}.${day}`;
  }

  render() {
    const {
      showDate,
      showTime = true,
      showSeconds = false,
      timezoneOffset = 0,
      leadingZeroHours = false,
      leadingZeroMinutes = true,
      leadingZeroSeconds = true
    } = this.props;
    const { timestamp, systemOffset } = this.state;

    const date = new Date(timestamp + (systemOffset + ((timezoneOffset * 60) || 0)) * 60 * 1000);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      <Block {...this.props}>
        {showDate && this.getFormattedDate(year, month, day)}
        {showDate && ' '}
        {showTime && (
          <>
            {this.getFormattedTime(hours, leadingZeroHours)}:
            {this.getFormattedTime(minutes, leadingZeroMinutes)}
            {showSeconds && `:${this.getFormattedTime(seconds, leadingZeroSeconds)}`}
          </>
        )}
      </Block>
    );
  }
}