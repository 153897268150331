import React from 'react';
import { Block } from '../block';
import { getBranchHTML } from '../../core/get-branch-html';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

export const onScrollListener = function (event) {
  if (this.adjustTimeout) {
    clearTimeout(this.adjustTimeout);
  }

  this.adjustTimeout = setTimeout(() => this.onScroll(), 1);
};

export class ContentAdvancedScrollBlock extends React.Component {
  scrollListener = null;
  adjustTimeout = null;
  scrollWrapperRef = null;

  constructor(props) {
    super(props);

    this.scrollListener = onScrollListener.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.scrollWrapperRef = React.createRef();
  }

  componentDidMount() {
    if (isDebug) {
      return;
    }

    window.addEventListener('scroll', this.scrollListener);
    window.addEventListener('wheel', this.scrollListener);
    window.addEventListener('touchmove', this.scrollListener);
  }

  componentWillUnmount() {
    if (isDebug) {
      return;
    }

    window.removeEventListener('scroll', this.scrollListener);
    window.removeEventListener('wheel', this.scrollListener);
    window.removeEventListener('touchmove', this.scrollListener);
  }

  onScroll() {
    if (!this.scrollWrapperRef || !this.scrollWrapperRef.current) {
      return;
    }

    const { scrollSpeed } = this.props;
    const childrenWrapper = this.scrollWrapperRef.current.querySelector('.t-element');

    if (!childrenWrapper) {
      return;
    }

    const children = Array.prototype.slice.call(childrenWrapper.children || []);

    if (!children) {
      return;
    }

    const wrapper = childrenWrapper.getBoundingClientRect();

    children.forEach(element => {
      if (!element.getAttribute('data-origin-top')) {
        element.setAttribute('data-origin-top', element.style.top);
      }

      const oy = element.getAttribute('data-origin-top');
      const validOy = oy.substr(-1) === '%' ? (parseFloat(oy) / 100) * wrapper.height : parseFloat(oy);
      const dy = Math.min(Math.max(0, -wrapper.y), wrapper.height - validOy);

      element.style.top = `calc(${oy} + ${dy * ((scrollSpeed / 100) || 1.0)}px)`;
    });
  }

  render() {
    const {
      content,
      _customChildrenProps,
      _library,
      _behaviours,
      __key
    } = this.props;
    const extractedContent = getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);
  
    return (
      <div ref={this.scrollWrapperRef}>
        <Block {...this.props}>
          {extractedContent}
        </Block>
      </div>
    );
  }
}
