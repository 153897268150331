import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

const isValidUrl = (url) => url && url.match(/^https?\:\/\/player\.vimeo\.com\//);

export const ContentAdvancedVimeo = (props) => {
  const { url } = props;

  return (
    <Block {...props}>
      {
        isValidUrl(extractVariable(url)) ? (
          <iframe
            src={extractVariable(url)}
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        ) : (
          <>
            <i className="fab fa-vimeo"></i> Vimeo video
          </>
        )
      }
    </Block>
  );
};