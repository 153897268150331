import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { getBranchHTML } from '../../core/get-branch-html';

const CollapsibleNavigationHandle = styled.div`
  padding: 20px;
  cursor: pointer;
`;

const CollapsibleNavigationIcon = styled.i`
  margin-right: 5px;
`;

const CollapsibleNavigationBody = styled.div`
  position: relative;
`;

const namespace = 'collapsible-navigation';

export class ContentAdvancedCollapsibleNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };
  }

  render() {
    const { content, _customChildrenProps, _library, _behaviours, __key } = this.props;
    const { collapsed } = this.state;
    const icon = collapsed ? 'chevron-circle-down' : 'chevron-circle-up';
    const label = collapsed ? 'menu' : 'menu';
    const body = collapsed ? null : getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);

    return (
      <Block {...{
        ...this.props,
        content: undefined
      }}>
        {!collapsed && (
          <CollapsibleNavigationBody id={this.props.id}>
            {body}
          </CollapsibleNavigationBody>
        )}
        <CollapsibleNavigationHandle
          onClick={() => {
            this.setState({
              collapsed: !this.state.collapsed
            });
          }}
          className={`${namespace}-handle`}
        >
          <CollapsibleNavigationIcon className={`${namespace}-icon far fa-${extractVariable(icon)}`}></CollapsibleNavigationIcon>
          <span className={`${namespace}-label`}>
            {label}
          </span>
        </CollapsibleNavigationHandle>
      </Block>
    );
  }
}
