/**
 * #element    text
 * 
 * #extends
 *  block
 * 
 * #props
 *  content   text content (does not render nested blocks)
 */
import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

export const ContentText = (props) => {
  const { size, fontSize, fontFamily, content, children } = props;

  let contentAsText;

  if (!content) {
    contentAsText = '';
  } else if (typeof content === 'string') {
    contentAsText = extractVariable(content);
  } else if (content instanceof Array) {
    contentAsText = content.filter(item => typeof item !== 'object').map(item => item.toString());
  } else {
    contentAsText = content.toString ? content.toString() : 'Invalid text content.';
  }

  return (
    <Block
      inline={!size}
      {...props}
      customStyle={{
        whiteSpace: 'pre-wrap',
        ...props.customStyle
      }}
    >
      {children ? children : <div>{contentAsText}</div>}
    </Block>
  );
};
