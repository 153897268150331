import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { Link } from 'gatsby';

const PagePreviewWrapper = styled.div`
  padding: 4px;

  &:not(:last-child) {
    margin-bottom: ${p => !isNaN(p.listSpacing) ? p.listSpacing : 24}px;
  }

  a {
    color: inherit;
  }

  b {
    display: inline-block;
    font-size: 1.25rem;
    margin: 8px 0;
  }

  img {
    width: 100%;
    border: solid 1px #ddd;
    border-radius: 2px;
  }

  u {
    display: inline-block;
    opacity: 0.5;
    font-size: 0.8rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const trimText = (text = '', maxSize) => {
  if (text.length < maxSize) {
    return text;
  }

  const trimmed = text.substr(0, maxSize - 3).split(' ');

  if (trimmed.length > 1) {
    trimmed.splice(-1, 1);
  }

  return `${trimmed.join(' ')} ...`;
};

export const ContentAdvancedPagePreview = (props) => {
  const { pages, showTitle, showDescription, showUrl, showImage, invertList, listSpacing } = props;

  const invertOp = invertList === true ? 'reverse' : 'concat';

  return (
    <Block {...props}>
      {
        (pages || [])
        .map(({ url, title, description, image }) => (
          <PagePreviewWrapper listSpacing={listSpacing}>
            {showImage !== false && image &&
              <Link to={url}>
                <img src={image} title={title} alt={title} />
              </Link>
            }
            {showTitle !== false && <Link to={url}>
              <b>
                {trimText(title, 64)}
              </b>
            </Link>}
            {showDescription !== false && <Link to={url}>
              <p>
                {trimText(description, 150)}
              </p>
            </Link>}
            {showUrl !== false && <Link to={url}>
              <u>
                {trimText(`${typeof window !== 'undefined' && window.location.origin}/${url}`, 75)}
              </u>
            </Link>}
          </PagePreviewWrapper>
        ))
        [invertOp]()
      }
    </Block>
  );
}
