import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

export const TrustBox = (props) => {
  const { trustBoxRef, templateId, businessUnitId, theme, _customChildrenProps } = props;

  let widgetTheme = theme || 'light';

  let templateIdString = extractVariable(templateId) || '5419b6a8b0d04a076446a9ad';
  let businessUnitIdString = extractVariable(businessUnitId);
  let widgetThemeString = extractVariable(widgetTheme) || 'light';

  if (!templateIdString || !businessUnitIdString) {
    return (
      <Block {...props}>
        <a href="https://www.trustpilot.com" target="_blank" rel="noopener">Missing Business ID</a>
      </Block>
    );
  }

  const customStyles = {};

  if (_customChildrenProps.simulateBehaviours === false) {
    customStyles.pointerEvents = 'none';
  }

  return (
    <Block {...props}>
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale={extractVariable(_customChildrenProps.plugin_trustpilot_language) || 'en-GB'}
        data-template-id={templateIdString}
        data-businessunit-id={businessUnitIdString}
        data-style-height="24px"
        data-theme={widgetThemeString}
        data-style-width="100%"
        style={customStyles}
      >
        <a href={`https://www.trustpilot.com/review/${extractVariable(businessUnitId)}`} target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </Block>
  );
};

export class ContentTrustPilot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null
    };

    this.trustBoxRef = React.createRef();
  }

  componentDidMount() {
    const { templateId, businessUnitId } = this.props;

    if (!templateId || !businessUnitId) {
      return;
    }

    this.state.id = businessUnitId;

    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }

  componentDidUpdate() {
    const { templateId, businessUnitId } = this.props;

    if (!templateId || !businessUnitId || businessUnitId === this.state.id) {
      return;
    }

    this.state.id = businessUnitId;

    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }

  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} {...this.props} />;
  }
}
