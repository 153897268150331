import React from 'react';
import { Block } from '../elements/block';
import { ContentText } from '../elements/content/text';
import { ContentLink } from '../elements/content/link';
import { ContentImage } from '../elements/content/image';
import { ContentInput } from '../elements/content/input';
import { ContentIcon } from '../elements/content/icon';
import { handleVariable } from './events/handle-variable';
import { handleGoToPage } from './events/handle-go-to-page';
import { handleUpdateView } from './events/handle-update-view';
import { handleCallApi } from './events/handle-call-api';
import { extractVariable } from '../helpers/extract-variable';
import { handleSendEmail } from './events/handle-send-email';
import { ContentVideo } from '../elements/content/video';
import { ContentAdvancedVimeo } from '../elements/advanced/vimeo';
import { ContentAdvancedCarousel } from '../elements/advanced/carousel';
import { ContentAdvancedCollapsibleNavigation } from '../elements/advanced/collapsible-navigation';
import { ContentTrustPilot } from '../elements/advanced/trust-pilot';
import { ContentContactForm } from '../elements/advanced/contact-form';
import { ContentAdvancedSocialShare } from '../elements/advanced/social-share';
import { ContentAdvancedPagePreview } from '../elements/advanced/page-preview';
import { ContentTimer } from '../elements/advanced/timer';
import { ContentAdvancedYouTube } from '../elements/advanced/youtube';
import { ContentRoot } from '../elements/content/root';
import { ContentShape } from '../elements/content/shape';
import { ContentAnimationBlock } from '../elements/advanced/animation-block';
import { ContentAdvancedBackgroundBlock } from '../elements/advanced/background-block';
import { ContentAdvancedScrollBlock } from '../elements/advanced/scroll-block';
import { ContentAdvanced3dModel } from '../elements/advanced/3d-model';
import { ContentAdvancedStripe } from '../elements/advanced/stripe';
import { ContentAdvancedInput } from '../elements/advanced/input';
import { handleDebug } from './events/handle-debug';
import { handleUpdateAppearance } from './events/handle-update-appearance';
import { handleConditional } from './events/handle-conditional';

if (typeof window !== 'undefined') {
  window.wtlVersion = 'v0.3.8';
}

export const mapSequenceToEvents = (sequence = [], options) => {
  const events = {};

  sequence.forEach(nodes => {
    const trigger = nodes[0].params['then'];

    events[trigger] = async function () {
      const flow = {};

      for (let i = 1; i < nodes.length; i++) {
        const { id, triggerNode, action, params } = nodes[i];

        if (triggerNode && flow[triggerNode] === false) {
          continue;
        }

        if (action === 'Variable') {
          await handleVariable(this, params);
        } else if (action === 'Go To Page') {
          await handleGoToPage(this, params);
        } else if (action === 'Update View') {
          await handleUpdateView(this, params);
        } else if (action === 'Call API') {
          await handleCallApi(this, params);
        } else if (action === 'Email') {
          await handleSendEmail(this, params);
        } else if (action === 'Test Value') {
          await handleDebug(this, params);
        } else if (action === 'Update Appearance') {
          await handleUpdateAppearance(this, params, options);
        } else if (action === 'If / Else') {
           await handleConditional(this, params, { flowId: id }, flow);
        } else {
          console.warn('WTL Studio', 'Unrecognised event');
        }
      }
    };
  });
  
  return events;
}

export const getBranchHTML =  (
  branch,
  key,
  options = {},
  library = [],
  behaviours = []
) => {
  if (!branch) {
    return null;
  }

  if (typeof branch === 'string') {
    return extractVariable(branch);
  }

  if (branch instanceof Array) {
    return branch.map((element, index) => getBranchHTML(element, index, options, library, behaviours));
  }

  const { type } = branch;
  const {
    hover,
    leave,
    select,
    mouseUp,
    mouseDown,
    mouseMove,
    simulateBehaviours,
    debugPreview
  } = options;

  if (branch.behaviour) {
    const { sequence } = behaviours.find(({ id }) => id === branch.behaviour);
    const events = mapSequenceToEvents(sequence, options);

    branch.events = events;
  }

  const customElementProps = {
    onMouseMove: (event) => {
      if (mouseMove) {
        event.preventDefault();

        mouseMove(event, { branch, element: event.currentTarget });
      }
    },
    onMouseDown: (event) => {
      if (mouseDown) {
        event.preventDefault();

        mouseDown(event, { branch, element: event.currentTarget });
      }
    },
    onMouseUp: (event) => {
      if (mouseUp) {
        event.preventDefault();

        mouseUp(event, { branch, element: event.currentTarget });
      }
    },
    onMouseEnter: (event) => {
      if (hover) {
        event.preventDefault();

        hover(event, {
          branch,
          element: event.currentTarget
        });
      }

      if (simulateBehaviours === false) {
        return;
      }

      if (branch.events && branch.events.onMouseEnter) {
        branch.events.onMouseEnter(); 
      }
    },
    onMouseLeave: (event) => {
      if (leave) {
        event.preventDefault();

        leave(event, {
          branch,
          element: event.currentTarget
        });
      }

      if (simulateBehaviours === false) {
        return;
      }

      if (branch.events && branch.events.onMouseLeave) {
        branch.events.onMouseLeave(); 
      }
    },
    onClick: (event, { rightClick, doubleClick } = {}) => {
      if (select) {
        select(event, {
          branch,
          element: type === 'root' ? event.currentTarget.querySelector('.t-element') : event.currentTarget
        }, {
          rightClick: rightClick || false,
          doubleClick: doubleClick || false
        });
      }

      if (simulateBehaviours === false) {
        return;
      }

      if (branch.events && branch.events.onClick) {
        branch.events.onClick(); 
      }
    },
    onContextMenu: (event) => {
      customElementProps.onClick(event, { rightClick: true});
    },
    onDoubleClick: (event) => {
      customElementProps.onClick(event, { doubleClick: true });
    },
    debugPreview
  };

  const props = {
    __key: key,
    ...branch,
    _customElementProps: customElementProps,
    _customChildrenProps: options,
    _library: library,
    _behaviours: behaviours
  };

  switch (type) {
    case 'block': 
      return <Block {...props} />;
    case 'text':
      return <ContentText {...props} />;
    case 'link':
      return <ContentLink {...props} />;
    case 'image':
      return <ContentImage {...props} />;
    case 'video':
      return <ContentVideo {...props} />;
    case 'input':
      return <ContentInput {...props} />;
    case 'icon':
      return <ContentIcon {...props} />;
    case 'shape':
      return <ContentShape {...props} />;
    case 'root': 
      return <ContentRoot {...props} />;
    // NOTE Advanced / Compound element types
    case 'advanced-vimeo':
      return <ContentAdvancedVimeo {...props} />;
    case 'advanced-carousel':
      return <ContentAdvancedCarousel {...props} />;
    case 'advanced-collapsible-navigation':
      return <ContentAdvancedCollapsibleNavigation {...props} />;
    case 'advanced-trust-pilot':
      return <ContentTrustPilot {...props} />;
    case 'advanced-contact-form':
      return <ContentContactForm {...props} />;
    case 'advanced-social-share':
      return <ContentAdvancedSocialShare {...props} />;
    case 'advanced-page-preview':
      return <ContentAdvancedPagePreview {...props} />;
    case 'advanced-timer':
      return <ContentTimer {...props} />;
    case 'advanced-youtube':
      return <ContentAdvancedYouTube {...props} />;
    case 'advanced-animation-block':
      return <ContentAnimationBlock {...props} />;
    case 'advanced-background-block':
      return <ContentAdvancedBackgroundBlock {...props} />;
    case 'advanced-scroll-block':
      return <ContentAdvancedScrollBlock {...props} />;
    case 'advanced-scroll-block':
      return <ContentAdvancedScrollBlock {...props} />;
    case 'advanced-3d-model':
      return <ContentAdvanced3dModel {...props} />;
    case 'advanced-stripe':
      return <ContentAdvancedStripe {...props} />;
    case 'advanced-input':
      return <ContentAdvancedInput {...props} />;
    default:
      return 'Empty block';
  }
};
