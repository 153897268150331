import { schemaSettings } from '../../config';

export const getElementSize = (size, mobile, tablet) => {
  let sizes = [];

  sizes.push(
    `wtl-grid-${mobile || tablet || size}-${schemaSettings.COLUMN_COUNT}`,
    `wtl-grid-lg-${size}-${schemaSettings.COLUMN_COUNT}`,
    `wtl-grid-xl-${size}-${schemaSettings.COLUMN_COUNT}`
  );

  if (mobile) {
    sizes.push(`wtl-grid-sm-${mobile}-${schemaSettings.COLUMN_COUNT}`);
  }

  if (tablet) {
    sizes.push(`wtl-grid-md-${tablet}-${schemaSettings.COLUMN_COUNT}`);
  }

  return sizes.join(' ');
};
