import { navigate } from 'gatsby';
import { isExternalUrl } from '../../helpers/is-external-url';
import { extractVariable } from '../../helpers/extract-variable';

export const handleGoToPage = async (context, params) => {
  const url = extractVariable.bind(context)(params['page-url']);

  if (typeof window === 'undefined') {
    return;
  }

  if (isExternalUrl(url)) {
    window.location = url;
  } else {
    navigate(url);
  }
};
