import React from 'react';
import styled from 'styled-components';
import { Block, parseStyleProps } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { getBranchHTML } from '../../core/get-branch-html';
import { getCachedStyling, isCachedStyling } from '../../helpers/styling-cache';
import { dashify } from '../../helpers/dashify';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

const AnimationIcon = styled.i`
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0px;
  padding: 2px 4px !important;
  font-size: 10px !important;
  background-color: #ccc !important;
  color: #000 !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, .2) !important;
  pointer-events: none;
  border-radius: 0 0 4px 0 !important;
  overflow: hidden;
  white-space: nowrap;
  z-index: 2;
`;

const getTimingFunction = (step, ease) => {
  if (step) {
    return `steps(1, end)`;
  }

  return `cubic-bezier(${ease})`;
}

const mapPropsToCss = (props, commonProps) => {
  const styleProps = parseStyleProps({
    ...commonProps,
    ...props,
    cssFontSize: props.fontSize,
    cssFontFamily: props.fontFamily
  });

  const cssStyles = Object.keys(styleProps).map((prop) => 
    styleProps[prop] ? `${dashify(prop)}: ${styleProps[prop]}` : undefined
  ).filter(Boolean).join(';');

  return cssStyles;
};

const getAnimationScript = ({
  id,
  keyframes = [],
  duration = 1,
  delay = 0,
  useStepFunction = true,
  easeFunction,
  iterationCount = 1,
  alternate = false,
  stopFrame,
  ...commonProps
}) => `
  @keyframes anim-gr-${id} {
    ${keyframes.length && keyframes.map((frame, n, frames) => frame && `
      ${n == 99 ? 100 : n}% {
        ${mapPropsToCss(frame, commonProps)}
      }
    `).join(' ')}
  }

  & > * {
    animation: anim-gr-${id} ${extractVariable(duration)}s ${getTimingFunction(extractVariable(useStepFunction), extractVariable(easeFunction), keyframes.filter(Boolean).length)} ${extractVariable(delay)}s ${extractVariable(iterationCount)} ${extractVariable(alternate) ? 'alternate' : 'normal'} both running;
  }
`;

export const ContentAnimationBlock = (props) => {
  const {
    id,
    content,
    _customChildrenProps,
    _library,
    _behaviours,
    stopFrame,
    keyframeUpdate,
    __key
  } = props;
  
  const extractedContent = getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);

  const animationId = `animgr-${id}-${keyframeUpdate}`;
  const stopAnimationId = `stanimgr-${id}`;
  const animationCached = isCachedStyling(animationId, undefined);
  let animationAsCss;

  if (!animationCached) {
    animationAsCss = getAnimationScript(props);
  }

  const animationStyle = isDebug && typeof stopFrame !== 'undefined' ? getCachedStyling(_library, stopAnimationId) : getCachedStyling(_library, animationId, undefined, animationAsCss);
  const animationWrapper = React.createElement(animationStyle, {}, (
    <Block {...props} {...((isDebug && (props.keyframes || [])[stopFrame]) || {})}>
      {isDebug && <AnimationIcon><i className="fa fa-fw fa-running" /><span>{stopFrame || 0}</span></AnimationIcon>}
      {extractedContent}
    </Block> 
  ));

  return animationWrapper;
};