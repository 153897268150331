/**
 * #element    link
 * 
 * #extends
 *  block
 * 
 * #props
 *  url        target url, renders internal or external link depending on target
 *             external links are opened in a new tab
 *  font
 *  color
 */
import React from 'react';
import { Link } from 'gatsby';
import { ContentText } from './text';
import { isExternalUrl } from '../../helpers/is-external-url';
import { extractVariable } from '../../helpers/extract-variable';
import { getBranchHTML } from '../../core/get-branch-html';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

export const ContentLink = (props) => {
  const { content, url, _customChildrenProps, _library, _behaviours, __key } = props;
  const extractedUrl = extractVariable(url);
  const isExternal = extractedUrl ? isExternalUrl(extractedUrl) : false;

  const extractedContent = typeof content !== 'string' ? (
    getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours)
  ) : extractVariable(`${content}`);

  if (isDebug) {
    return (
      <ContentText {...props}>
        {extractedContent}
      </ContentText>
    );
  }

  return (
    <ContentText {...props}>
      {
        isExternal ?
        (
          <a
            href={extractedUrl || '#'}
            target="_blank"
            rel="noopener noreferrer"
          >
            {extractedContent}
          </a>
        ) :
        (
          <Link
            to={extractedUrl || '#'}
          >
            {extractedContent}
          </Link>
        )
      }
    </ContentText>
  );
};
