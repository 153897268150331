import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

const carouselTransitions = {
  pop: {
    in: keyframes`
      from {
        opacity: 0.0;
        transform: scale(0.9);
      }

      to {
        opacity: 1.0;
        transform: scale(1.0);
      }
    `,
    out: keyframes`
      from {
        opacity: 1.0;
        transform: scale(1);
      }

      to {
        opacity: 0.0;
        transform: scale(0.9);
      }
    `
  }
};

const CarouselWrapper = styled.div`
  ${p => p.handlesOnHover && `
    .carousel-handle {
      opacity: 0;
      transition: opacity .3s ease;
    }

    &:hover .carousel-handle {
      opacity: 1;
      cursor: pointer;
    }
  `}
`;

const CarouselImage = styled.img`
  ${p => (
    p.transition ?
      (css)`animation: ${carouselTransitions[p.transition][p.transitionState]} .3s ease 1 both;`
      : ''
  )}
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
`;

const CarouselHandle = styled.div`
  position: absolute;
  top: 50%;
  ${p => p.reverse ? 'left: 0;' : 'right: 0;'}
  font-size: 32px;
  transform: ${p => p.reverse ? 'translateY(-50%) scaleX(-1.0)' : 'translateY(-50%)'};
  z-index: 2;
`;

export class ContentAdvancedCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transitionState: 'in',
      showIndex: 0
    };
  }

  getImagesList() {
    const {
      image1,
      image2,
      image3,
      image4
    } = this.props;

    return [ image1, image2, image3, image4 ].filter(Boolean).map(extractVariable);
  }

  getNextIndex() {
    return this.state.showIndex < this.getImagesList().length - 1 ? this.state.showIndex + 1 : 0;
  }

  getPreviousIndex() {
    return this.state.showIndex > 0 ? this.state.showIndex - 1 : this.getImagesList().length - 1;
  }

  changeIndex(direction = 'next') {
    if (this.state.transitionState === 'out') {
      return;
    }

    this.setState({
      transitionState: 'out'
    });

    setTimeout(() => {
      this.setState({
        showIndex: direction === 'next' ? this.getNextIndex() : this.getPreviousIndex(),
        transitionState: 'in'
      });
    }, 350);
  }

  render() {
    const {
      transitionState,
      showIndex
    } = this.state;
    const {
      displayHandles = 'both',
      handlesMode = 'always-on',
      handleIcon = 'chevron-right',
      handleIconFamily = 'l',
      transition = 'pop'
    } = this.props;
    const images = this.getImagesList();

    return (
      <Block
        {...this.props}
        align="center"
      >
        <CarouselWrapper handlesOnHover={handlesMode === 'on-hover'}>
        {
          (displayHandles === 'both' || displayHandles === 'prev') &&
          (
            <CarouselHandle
              className="carousel-handle"
              onClick={() => this.changeIndex('previous')}
              reverse={true}
            >
              <i className={`fa${extractVariable(handleIconFamily)} fa-${extractVariable(handleIcon)}`} />
            </CarouselHandle>
          )
        }
        <CarouselImage
          src={images[showIndex]}
          transition={extractVariable(transition)}
          transitionState={transitionState}
          width={this.props.width}
          height={this.props.height}
        ></CarouselImage>
        {
          (displayHandles === 'both' || displayHandles === 'next') &&
          (
            <CarouselHandle
              className="carousel-handle"
              onClick={() => this.changeIndex('next')}
            >
              <i className={`fa${extractVariable(handleIconFamily)} fa-${extractVariable(handleIcon)}`} />
            </CarouselHandle>
          )
        }
        </CarouselWrapper>
      </Block>
    )
  }
}

