import React from 'react';
import { ResponsiveWrapper } from 'wtl-components-library/web/responsive-wrapper/responsive-wrapper';
import { SchemaContext } from './schema-context';
import { PageHTML } from './components/page-html';

export class SchemaRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: 0,
      refreshSchema: this.refreshSchema.bind(this),
      preloadSet: false
    };
  }

  componentDidMount() {
    this.refreshFontStyling();
  }

  componentDidUpdate() {
    this.refreshFontStyling();
  }
  
  refreshFontStyling() {
    const { preloadSet } = this.state;

    if (preloadSet) {
      return;
    }

    if (typeof document !== 'undefined') {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.refreshSchema();
        }
      };

      if (document.fonts) {
        document.fonts.ready.then(() => {
          this.refreshSchema();
        });
      }

      this.state.preloadSet = true;
      this.forceUpdate();
    }
  }

  refreshSchema() {
    this.setState({
      refreshKey: Date.now() * Math.random()
    });
  }

  render() {
    const { schema, options, library, behaviours } = this.props;

    return (
      <SchemaContext.Provider value={this.state}>
        <ResponsiveWrapper>
          <PageHTML
            schema={schema()}
            library={library || []}
            options={options || {}}
            behaviours={behaviours || []}
          />
        </ResponsiveWrapper>
      </SchemaContext.Provider>
    );
  }
}
