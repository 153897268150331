import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { mapSequenceToEvents } from '../../core/get-branch-html';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

export class ContentAdvancedInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: isDebug ? '(Placeholder value)' : undefined
    };
    this.onChange = this.onChange.bind(this);

    if (typeof window !== 'undefined') {
      this.state.value = sessionStorage[this.getInputKey()] || undefined;
    }
  }

  getInputKey() {
    if (typeof window === 'undefined') {
      return;
    }

    const { id } = this.props;

    return `${window.location.pathname}/${id}`;
  }

  onChange(event) {
    event.preventDefault();

    const {
      inputEvents
    } = this.props;
    const value = event.target.value;

    this.setState({
      value: value
    });

    if (typeof window !== 'undefined') {
      sessionStorage[this.getInputKey()] = value;
    }

    if (inputEvents && inputEvents.onChange) {
      inputEvents.onChange.bind(this)(event);
    }
  }

  renderInput(inlineProps) {
    const { id, variant, inputOptions } = this.props;
    const { value } = this.state;

    if (variant === 'textarea') {
      return (
        <textarea key={id} {...inlineProps}>
          {value}
        </textarea>
      );
    } else if (variant === 'select') {
      return (
        <select value={value} key={id} {...inlineProps}>
          {(inputOptions || []).map(option => (
            <option value={option}>{option}</option>
          ))}
        </select>
      );
    } else {
      return (
        <input key={id} {...inlineProps} />
      );
    }
  }

  render() {
    const {
      id,
      size,
      variant,
      defaultValue,
      placeholder,
      inputEvents,
      inputBehaviour,
      _behaviours: behaviours,
      _customChildrenProps
    } = this.props;
    const { value } = this.state;

    let behaviourEvents = {};
    let allEvents;

    if (inputBehaviour) {
      const { sequence } = behaviours.find(({ id }) => id === inputBehaviour);
      
      behaviourEvents = mapSequenceToEvents(sequence, _customChildrenProps);
    }

    allEvents = {
      ...behaviourEvents,
      ...(inputEvents || [])
    };

    Object.keys(allEvents).forEach(key => {
      allEvents[key] = allEvents[key].bind(this);
    });

    const inputSize = variant === 'select' ? '100%' : '92%';

    const inlineProps = {
      type: extractVariable(variant),
      defaultValue: extractVariable(defaultValue),
      value: value,
      placeholder: isDebug && !placeholder ? 'WTL Studio' : extractVariable(placeholder),
      ...(allEvents || {}),
      onChange: this.onChange,
      style: {
        position: 'absolute',
        top: variant === 'select' ? 0 : '4%',
        left: variant === 'select' ? 0 : '4%',
        display: 'inline-block',
        width: inputSize,
        height: inputSize,
        minHeight: inputSize,
        maxHeight: inputSize,
        minWidth: inputSize,
        maxWidth: inputSize,
        boxSizing: 'border-box',
        border: 0,
        background: 'none',
        pointerEvents: isDebug ? 'none' : ''
      }
    };

    return (
      <Block
        key={`${id}-wrapper`}
        inline={!size}
        {...this.props}  
      >
        {this.renderInput(inlineProps)}
      </Block>
    );
  }
}
