import { callApi } from '../../../config';
import { extractVariable } from '../../helpers/extract-variable';

export const handleSendEmail = async (context, params) => {
  const replyTo = params['reply-to'];
  const fields = params['fields'];
  let payload = {};

  const replyToEmail = extractVariable.bind(context)(replyTo);

  fields.forEach((definition, index) => {
    const reference = extractVariable.bind(context)(definition);

    if (typeof reference === 'object') {
      payload = {
        ...payload,
        ...reference
      };
    } else {
      payload = {
        ...payload,
        [`Value ${index + 1}`]: reference
      };
    }
  });

  await callApi('export', 'POST', {
    replyTo: replyToEmail,
    language: 'en',
    formData: payload
  });
};
