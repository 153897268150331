/**
 * #element    input
 * 
 * #extends
 *  block
 * 
 * #props
 *  inputType     input type
 *  defaultValue  initial input value
 *  placeholder
 *  inputEvents   directly injected React-style event listeners
 *  validation    validator method (value: any) => boolean OR
 *                validator RegExp
 * 
 * #validation
 *  'pristine' | 'valid' | 'invalid'
 * 
 *  requires validation prop set, otherwise always valid
 *  initially -> 'pristine'
 *  if validation returns true -> 'valid'
 *  if validation returns false -> 'invalid'
 * 
 *  <input> element has classNames with exactly matching names appended on validation change
 */
import React from 'react';
import { Block } from '../block';
import { SchemaContext } from '../../core/schema-context';
import { extractVariable } from '../../helpers/extract-variable';
import { mapSequenceToEvents } from '../../core/get-branch-html';

export class ContentInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: 'pristine',
      value: null
    };
  }

  onChange(event) {
    event.preventDefault();

    const {
      inputEvents,
      validation
    } = this.props;
    const value = event.target.value;

    this.setState({
      valid: validation ?
        (
          typeof validation === 'function' ?
          (validation(value) ? 'valid' : 'invalid') :
          ((new RegExp(extractVariable(validation))).test(value) ? 'valid' : 'invalid')
        ) :
        'valid',
      value: value
    });

    if (inputEvents && inputEvents.onChange) {
      inputEvents.onChange.bind(this)(event);
    }
  }

  render() {
    const {
      id,
      size,
      inputType,
      defaultValue,
      placeholder,
      inputEvents,
      inputBehaviour,
      _behaviours: behaviours,
      _customChildrenProps
    } = this.props;
    const { valid, value } = this.state;

    let behaviourEvents = {};
    let allEvents;

    if (inputBehaviour) {
      const { sequence } = behaviours.find(({ id }) => id === inputBehaviour);
      
      behaviourEvents = mapSequenceToEvents(sequence, _customChildrenProps);
    }

    allEvents = {
      ...behaviourEvents,
      ...(inputEvents || [])
    };

    Object.keys(allEvents).forEach(key => {
      allEvents[key] = allEvents[key].bind(this);
    });

    const inlineProps = {
      className: valid,
      type: extractVariable(inputType),
      defaultValue: extractVariable(defaultValue),
      value: value,
      placeholder: extractVariable(placeholder),
      ...(allEvents || {}),
      onChange: this.onChange.bind(this)
    };

    return (
      <Block
        key={`${id}-wrapper`}
        inline={!size}
        {...this.props}  
      >
        {
          inputType === 'textarea' ?
            <textarea key={id} {...inlineProps}>
              {value}
            </textarea> :
            <input key={id} {...inlineProps} />
        }
      </Block>
    );
  }
}

ContentInput.contextType = SchemaContext;
