import React from 'react';
import Html from 'slate-html-serializer';

const getDataAsJson = (map) => {
  // const obj = {};

  // for (let [k, v] of map) {
  //   obj[k] = v;
  // }

  // return obj;
  return map;
};

const rules = [
  {
    serialize: ({ object, type, data }, children) => {
      if (object === 'block') {
        return React.createElement(
          'div',
          getDataAsJson(data),
          children
        );
      }
    }
  },
  {
    serialize: ({ object, type, data }, children) => {
      if (object === 'mark') {
        return React.createElement(
          type,
          getDataAsJson(data),
          children
        );
      }
    }
  },
  {
    serialize: ({ object, type, data }, children) => {
      if (object === 'inline') {
        const jsonData = getDataAsJson(data);
        const style = {
          fontFamily: jsonData.fontFace || ''
        };

        return React.createElement(
          type,
          {
            ...jsonData,
            style
          },
          children
        );
      }
    }
  }
];

export const htmlSerializer = new Html({ rules });