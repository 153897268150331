import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

const SocialShareLabel = styled.div`
  padding-top: 8px;
  font-size: 8.8px;
  color: rgba(0, 0, 0, .5);
`;

const SocialShareWrapper = styled.div`
  font-size: 10px;

  .resp-sharing-button__link,
  .resp-sharing-button__icon {
    display: inline-block;
  }

  .resp-sharing-button__link {
    text-decoration: none;
    color: #fff;
    margin: 0.5em;

    &:first-child {
      margin-left: 0;
    }

    & > * {
      pointer-events: none;
    }
  }

  .resp-sharing-button {
    border-radius: 5px;
    transition: 25ms ease-out;
    padding: 0.5em 0.75em;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  }

  .resp-sharing-button__icon svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.4em;
    vertical-align: top;
  }

  .resp-sharing-button--small svg {
    margin: 0;
    vertical-align: middle;
  }

  /* Non solid icons get a stroke */
  .resp-sharing-button__icon {
    stroke: #fff;
    fill: none;
  }

  /* Solid icons get a fill */
  .resp-sharing-button__icon--solid,
  .resp-sharing-button__icon--solidcircle {
    fill: #fff;
    stroke: none;
  }

  .resp-sharing-button--twitter {
    background-color: #55acee;
  }

  .resp-sharing-button--twitter:hover {
    background-color: #2795e9;
  }

  .resp-sharing-button--pinterest {
    background-color: #bd081c;
  }

  .resp-sharing-button--pinterest:hover {
    background-color: #8c0615;
  }

  .resp-sharing-button--facebook {
    background-color: #3b5998;
  }

  .resp-sharing-button--facebook:hover {
    background-color: #2d4373;
  }

  .resp-sharing-button--tumblr {
    background-color: #35465C;
  }

  .resp-sharing-button--tumblr:hover {
    background-color: #222d3c;
  }

  .resp-sharing-button--reddit {
    background-color: #5f99cf;
  }

  .resp-sharing-button--reddit:hover {
    background-color: #3a80c1;
  }

  .resp-sharing-button--google {
    background-color: #dd4b39;
  }

  .resp-sharing-button--google:hover {
    background-color: #c23321;
  }

  .resp-sharing-button--linkedin {
    background-color: #0077b5;
  }

  .resp-sharing-button--linkedin:hover {
    background-color: #046293;
  }

  .resp-sharing-button--email {
    background-color: #777;
  }

  .resp-sharing-button--email:hover {
    background-color: #5e5e5e;
  }

  .resp-sharing-button--xing {
    background-color: #1a7576;
  }

  .resp-sharing-button--xing:hover {
    background-color: #114c4c;
  }

  .resp-sharing-button--whatsapp {
    background-color: #25D366;
  }

  .resp-sharing-button--whatsapp:hover {
    background-color: #1da851;
  }

  .resp-sharing-button--hackernews {
  background-color: #FF6600;
  }
  .resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

  .resp-sharing-button--vk {
    background-color: #507299;
  }

  .resp-sharing-button--vk:hover {
    background-color: #43648c;
  }

  .resp-sharing-button--facebook {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .resp-sharing-button--facebook:hover,
  .resp-sharing-button--facebook:active {
    background-color: #2d4373;
    border-color: #2d4373;
  }

  .resp-sharing-button--twitter {
    background-color: #55acee;
    border-color: #55acee;
  }

  .resp-sharing-button--twitter:hover,
  .resp-sharing-button--twitter:active {
    background-color: #2795e9;
    border-color: #2795e9;
  }

  .resp-sharing-button--linkedin {
    background-color: #0077b5;
    border-color: #0077b5;
  }

  .resp-sharing-button--linkedin:hover,
  .resp-sharing-button--linkedin:active {
    background-color: #046293;
    border-color: #046293;
  }

  .resp-sharing-button--reddit {
    background-color: #5f99cf;
    border-color: #5f99cf;
  }

  .resp-sharing-button--reddit:hover,
  .resp-sharing-button--reddit:active {
    background-color: #3a80c1;
    border-color: #3a80c1;
  }

  .resp-sharing-button--whatsapp {
    background-color: #25D366;
    border-color: #25D366;
  }

  .resp-sharing-button--whatsapp:hover,
  .resp-sharing-button--whatsapp:active {
    background-color: #1DA851;
    border-color: #1DA851;
  }
`;

const getPageUrl = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  return `${window.location.href}${window.location.href.substr(-1) === '/' ? '' : '/'}`;
}

const getPageTitle = () => {
  if (typeof document === 'undefined') {
    return '';
  }

  return document.title;
}

const renderFacebook = () => (
  <a className="resp-sharing-button__link" href={`https://facebook.com/sharer/sharer.php?u=${getPageUrl()}`} target="_blank" rel="noopener" aria-label="Facebook">
    <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--medium">
      <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
      </div>
      Facebook
    </div>
  </a>
);

const renderTwitter = () => (
  <a className="resp-sharing-button__link" href={`https://twitter.com/intent/tweet/?text=${getPageTitle()}&amp;url=${getPageUrl()}`} target="_blank" rel="noopener" aria-label="Twitter">
    <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg></div>Twitter</div>
  </a>
);

const renderLinkedIn = () => (
  <a className="resp-sharing-button__link" href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${getPageUrl()}&amp;title=${getPageTitle()}&amp;summary=${getPageTitle()}&amp;source=${getPageUrl()}`} target="_blank" rel="noopener" aria-label="LinkedIn">
    <div className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"/></svg></div>LinkedIn</div>
  </a>
);

const renderReddit = () => (
  <a className="resp-sharing-button__link" href={`https://reddit.com/submit/?url=${getPageUrl()}&amp;resubmit=true&amp;title=${getPageTitle()}`} target="_blank" rel="noopener" aria-label="Reddit">
    <div className="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z"/></svg></div>Reddit</div>
  </a>
);

export const ContentAdvancedSocialShare = (props) => {
  return (
    <Block {...props}>
      <SocialShareLabel>
        share
      </SocialShareLabel>
      <SocialShareWrapper>
        {renderFacebook()}
        {renderTwitter()}
        {renderLinkedIn()}
        {renderReddit()}
      </SocialShareWrapper>
    </Block>
  );
};