import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { iNaN } from '../../helpers/i-nan';
import { extractVariableUnit } from '../../helpers/extract-variable-unit';

const ShapeSvg = styled.svg`
  position: relative;
  width: ${p => p.cssWidth};
  height: ${p => p.cssHeight};
  overflow: visible;

  &, * {
    overflow: visible;
    vector-effect: non-scaling-stroke;
    shape-rendering: geometricPrecision;
  }
`;

const getStrokeProps = ({
  variant,
  cssBorderStyle,
  cssBorderColor,
  cssBorderThickness,
  cssBorderRadius
}) => {
  if (!extractVariable(cssBorderStyle)) {
    return;
  }

  const borderWidth = parseFloat(extractVariable(cssBorderThickness) || '1px');

  return {
    stroke: extractVariable(cssBorderColor) || 'none',
    strokeWidth: iNaN(variant === 'rectangle' ? borderWidth * 2 : borderWidth),
    strokeLinecap: !!extractVariable(cssBorderRadius) ? 'round' : 'miter',
    strokeLinejoin: !!extractVariable(cssBorderRadius) ? 'round' : 'miter',
    strokeDasharray: extractVariable(cssBorderStyle) === 'dashed' ? '5,5' : undefined,
    rx: iNaN(extractVariable(cssBorderRadius) || 0),
    ry: iNaN(extractVariable(cssBorderRadius) || 0),
  }
};

const getFillProps = ({
  id,
  cssWidth,
  cssHeight,
  cssBackgroundColor,
  cssBackgroundGradientDirection,
  cssBackgroundColorB,
  cssBackgroundImage,
  cssBackgroundPosition,
  cssBackgroundRepeat,
  cssBackgroundFit,
  cssBackgroundType
}) => {
  let defId = `shape-bg-${id || (Math.random() * 256)}`;
  const defs = [];
  const width = extractVariable(cssWidth);
  const height = extractVariable(cssHeight);

  if (cssBackgroundType === 'image') {
    const fit = extractVariable(cssBackgroundFit);
    const [positionY, positionX] = (extractVariable(cssBackgroundPosition) || 'center center').split(' ');
    const alignY = ({
      'top': '-25%',
      'center': '0%',
      'bottom': '25%'
    })[positionY];
    const alignX = ({
      'left': '-25%',
      'center': '0%',
      'right': '25%'
    })[positionX];

    defs.push(
      <pattern
        id={defId}
        width={iNaN(width)}
        height={iNaN(height)}
        patternUnits="userSpaceOnUse"
      >
        <image
          xlinkHref={extractVariable(cssBackgroundImage)}
          x={iNaN(alignX)}
          y={iNaN(alignY)}
          width={iNaN(fit === 'auto' ? undefined : width)}
          height={iNaN(fit === 'auto' ? undefined : height)}
          preserveAspectRatio={fit === 'cover' ? 'none' : 'xMidYMid'}
        />
      </pattern>
    );
  }

  if (cssBackgroundType === 'gradient') {
    if (cssBackgroundGradientDirection === 'radial') {
      defs.push(
        <radialGradient id={defId}>
          <stop offset="0%" stop-color={extractVariable(cssBackgroundColor)} />
          <stop offset="100%" stop-color={extractVariable(cssBackgroundColorB)} />
        </radialGradient>
      );
    } else {
      defs.push(
        <linearGradient id={defId} {...(cssBackgroundGradientDirection === 'vertical' ? ({ x1: 0, x2: 0, y1: 0, y2: 1 }) : {})}>
          <stop offset="0%" stop-color={extractVariable(cssBackgroundColor)} />
          <stop offset="100%" stop-color={extractVariable(cssBackgroundColorB)} />
        </linearGradient>
      );
    }
  }

  return {
    fill: {
      fill: defs.length > 0 ? `url(#${defId})` : (extractVariable(cssBackgroundColor) || 'transparent')
    },
    fillDefs: defs
  };
};

export const ContentShape = (props) => {
  const {
    variant = 'ellipse',
    waveFrequency = 1
  } = props;
  const width = extractVariable(props.cssWidth);
  const height = extractVariable(props.cssHeight);
  const widthf = parseFloat(width);
  const heightf = parseFloat(height);
  const widthu = extractVariableUnit(props.cssWidth);
  const heightu = extractVariableUnit(props.cssHeight);
  let borderThickness = parseFloat(extractVariable(props.cssBorderThickness));

  if (isNaN(borderThickness) || !borderThickness) {
    borderThickness = 0;
  }

  const stroke = getStrokeProps(props);
  const { fill, fillDefs } = getFillProps(props);
  let pathSteps = '';

  if (variant === 'wave') {
    const waveStep = 100 / waveFrequency;

    pathSteps = Array(waveFrequency).fill(0).map((_, n) =>
      `Q ${parseInt(waveStep * (n + .5), 10)} ${n % 2 === 0 ? 0 : 100} ${parseInt(waveStep * (n + 1), 10)} 50`
    ).join(' ');
  }


  return (
    <Block {...({
      ...props,

      cssBackgroundColor: undefined,
      cssBackgroundGradientDirection: undefined,
      cssBackgroundColorB: undefined,
      cssBackgroundImage: undefined,
      cssBackgroundPosition: undefined,
      cssBackgroundRepeat: undefined,
      cssBackgroundFit: undefined,
      cssBackgroundType: undefined,
      cssBorderStyle: undefined,
      cssBorderColor: undefined,
      cssBorderThickness: undefined,
      cssBorderRadius: undefined,

      cssBackgroundColorHover: undefined,
      cssBackgroundGradientDirectionHover: undefined,
      cssBackgroundColorBHover: undefined,
      cssBackgroundImageHover: undefined,
      cssBackgroundPositionHover: undefined,
      cssBackgroundRepeatHover: undefined,
      cssBackgroundFitHover: undefined,
      cssBackgroundTypeHover: undefined,
      cssBorderStyleHover: undefined,
      cssBorderColorHover: undefined,
      cssBorderThicknessHover: undefined,
      cssBorderRadiusHover: undefined,
      
      cssBackgroundColorActive: undefined,
      cssBackgroundGradientDirectionActive: undefined,
      cssBackgroundColorBActive: undefined,
      cssBackgroundImageActive: undefined,
      cssBackgroundPositionActive: undefined,
      cssBackgroundRepeatActive: undefined,
      cssBackgroundFitActive: undefined,
      cssBackgroundTypeActive: undefined,
      cssBorderStyleActive: undefined,
      cssBorderColorActive: undefined,
      cssBorderThicknessActive: undefined,
      cssBorderRadiusActive: undefined
    })}>
      <ShapeSvg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        {...props}
      >
        {fillDefs}
        {({
          'ellipse': (
            <ellipse
              cx="50%"
              cy="50%"
              {...stroke}
              {...fill}
              rx="50%"
              ry="50%"
            />
          ),
          'rectangle': (
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              {...stroke}
              {...fill}
            />
          ),
          'line': (
            <line
              x1={0}
              y1="50%"
              x2="100%"
              y2="50%"
              {...stroke}
              {...fill}
            />
          ),
          'wave': (
            <path
              d={`
                M 0 50
                ${pathSteps}
              `}
              {...stroke}
              {...fill}
            />
          )
        })[variant]}
      </ShapeSvg>
    </Block>
  );
};