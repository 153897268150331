import { callApi } from '../../../config';
import { extractVariable } from '../../helpers/extract-variable';

export const handleUpdateAppearance = async (context, params, options) => {
  const element = extractVariable.bind(context)(params['element']);
  const value = extractVariable.bind(context)(params['value']);
  const property = extractVariable.bind(context)(params['property']);
  
  if (typeof window === 'undefined') {
    return;
  }

  let elementId;

  if (typeof element === 'string') {
    elementId = element;
  } else {
    elementId = element && element.id;
  }

  if (!elementId) {
    return;
  }

  let targetNode;
  const traverseSchema = (node) => {
    if (targetNode) {
      return true;
    }

    if (node.id === elementId) {
      targetNode = node;
      return true;
    }

    if (node.content instanceof Array) {
      node.content.forEach(traverseSchema);
    }
  };

  options.schemaRef.some(_root => traverseSchema(_root));

  if (targetNode) {
    targetNode[property] = value;
  }

  return true;
};
