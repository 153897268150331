import VariableService from '../../services/variable.service';
import { extractVariable } from '../../helpers/extract-variable';

export const handleVariable = async (context, params) => {
  const id = params['set-id'];
  const value = extractVariable.bind(context)(params['set-value']);

  if (!id) {
    return;
  }

  VariableService.setVar(id, value);
};
