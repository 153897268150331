import styled from 'styled-components';

if (typeof window !== 'undefined' && typeof window.stylingCache === 'undefined') {
  window.stylingCache = [];
}

export const isCachedStyling = (id, injectSuffix = '') => {
  const sid = `${id || 'div'}${injectSuffix}`.trim();
  const cached = typeof window !== 'undefined' && window.stylingCache[sid];

  return !!cached;
}

export const getCachedStyling = (library, id, injectSuffix = '', injectStyles = '') => {
  const sid = `${id || 'div'}${injectSuffix}`.trim();
  const cached = typeof window !== 'undefined' && window.stylingCache[sid];
  let wrapper;

  if (cached) {
    wrapper = cached;
  } else {
    const customWrapperCss = id && library.find((item) => item.id === id);

    if (customWrapperCss) {
      wrapper = (styled[customWrapperCss.dom] || styled.div)`${customWrapperCss.styling}`;
    } else {
      wrapper = (styled[id] || styled.div)``;
    }

    if (injectStyles) {
      wrapper.componentStyle.rules.push(injectStyles);
    }

    if (typeof window !== 'undefined') {
      window.stylingCache[sid] = wrapper;
    }
  }

  return wrapper;
};
