/**
 * #element    image
 * 
 * #extends
 *  block
 * 
 * #props
 *  url         image url
 *  width
 *  height
 *  imageStyle  customStyle prop styles wrapper, imageStyle prop styles <img> element directly
 */
import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

export const ContentImage = (props) => {
  const { url, width, height, imageStyle, imageTitle, scaleToSize, preserveRatio, sharpen, cssWidth, cssHeight, _customChildrenProps } = props;
  const { renderHdImages } = _customChildrenProps;

  let scaledWidth = scaleToSize ? width || cssWidth : width;
  let scaledHeight = scaleToSize ? height || cssHeight : height;
  const renderAsBackground = scaleToSize && preserveRatio;

  if (scaleToSize) {
    scaledWidth = '100%';
    scaledHeight = '100%';
  }

  const image = renderAsBackground ? (
    <div
      style={{
        width: scaledWidth,
        height: scaledHeight,
        maxWidth: scaledWidth,
        maxHeight: scaledHeight,
        ...imageStyle,
        backgroundImage: `url(${extractVariable(url)})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: scaleToSize ? 'contain' : 'auto',
        imageRendering: sharpen ? 'pixelated': ''
      }}
    >
      <img
        src={extractVariable(url)}
        alt={extractVariable(imageTitle)}
        title={extractVariable(imageTitle)}
        style={{ opacity: 0 }}
      />
    </div>
  ) : (
    <img
      src={extractVariable(url)}
      alt={extractVariable(imageTitle)}
      title={extractVariable(imageTitle)}
      style={{
        width: scaledWidth,
        height: scaledHeight,
        maxWidth: '100%',
        maxHeight: '100%',
        imageRendering: sharpen ? 'pixelated': '',
        ...imageStyle
      }}
    />
  );

  return (
    <Block
      {...props}
      customStyle={{
        lineHeight: 0,
        ...(props.customStyle || {})
      }}
    >
    {
      renderHdImages !== false ?
      image
      : (
        <div style={{
          textAlign: 'center',
          fontSize: 68,
          opacity: .3
        }}>
          <i className="fas fa-image"></i>
        </div>
      )
    }
    </Block>
  );
};
