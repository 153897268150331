import React from 'react';
import VariablesService from '../services/variable.service';
import { htmlSerializer } from './html-serializer';

const variablePrefix = '_$v';
const variableKeyPairPrefix = '_$ref';
const domInputPrefix = '_$inp';
const domElementPrefix = '_$el';
const behaviourParamPrefix = '_$bparam';
const richTextPrefix = '_$rtejson';

export const extractVariable = function (value, { richTextAsJson } = {}) {
  if (!value || typeof value.startsWith === 'undefined') {
    return value;
  }

  let key;
  const safeValue = `${value}`.trim();

  if (safeValue.startsWith(variablePrefix)) {
    key = safeValue.substr(variablePrefix.length);

    return VariablesService.getVar(key);
  } else if (safeValue.startsWith(variableKeyPairPrefix)) {
    key = safeValue.substr(variableKeyPairPrefix.length);

    return {
      [key]: VariablesService.getVar(key)
    };
  } else if (safeValue.startsWith(domInputPrefix)) {
    if (typeof document === 'undefined') {
      return;
    }

    key = safeValue.substr(domInputPrefix.length);

    const inputElement = document.querySelector(`#${key} > input`);

    if (inputElement) {
      return inputElement.value;
    }

    const textareaElement = document.querySelector(`#${key} > textarea`);
    
    if (textareaElement) {
      return textareaElement.value;
    }

    const selectElement = document.querySelector(`#${key} > select`);
    
    if (selectElement) {
      return selectElement.value;
    }

    return;
  } else if (safeValue.startsWith(domElementPrefix)) {
    if (typeof document === 'undefined') {
      return;
    }

    key = safeValue.substr(domElementPrefix.length);

    return document.querySelector(`#${key}`);
  } else if (safeValue.startsWith(behaviourParamPrefix)) {
    // NOTE This can be used only via behaviours
    //      Behaviour param is extracted from event context
    key = safeValue.substr(behaviourParamPrefix.length);

    if (!key) {
      key = '0';
    }

    if (this.props) {
      let value = this.props[`behaviourParam${key}`];

      if (!value && key === '0') {
        value = this.props.behaviourParam;
      }
    
      return extractVariable.bind(this)(value);
    }

    return safeValue;
  } else if (safeValue.startsWith(richTextPrefix)) {
    key = decodeURIComponent(safeValue.substr(richTextPrefix.length));

    if (richTextAsJson !== true) {
      return <div dangerouslySetInnerHTML={{
        __html: htmlSerializer.serialize(JSON.parse(key))
      }}></div>
    }

    try {
      return JSON.parse(key);
    } catch (error) {
      return decodeURIComponent(safeValue);
    }
  } else {
    return decodeURIComponent(safeValue);
  }
};
