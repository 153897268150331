/**
 * #element    icon
 * 
 * #extends
 *  text
 * 
 * #props
 *  content          font awesome icon id
 *  iconFamily      font awesome icon type (solid, light, regular)
 */
import React from 'react';
import { ContentText } from './text';
import { extractVariable } from '../../helpers/extract-variable';

export const ContentIcon = (props) => {
  const {
    content = 'question-square',
    iconFamily = 'r'
  } = props;

  return (
    <ContentText {...props}>
      <i className={`fa${iconFamily} fa-${extractVariable(content)}`}></i>
    </ContentText>
  );
};
