import VariableService from '../../services/variable.service';
import { extractVariable } from '../../helpers/extract-variable';

export const handleConditional = async (context, params, options, flow) => {
  const valueA = extractVariable.bind(context)(params['value-a']);
  const valueB = extractVariable.bind(context)(params['value-b']);
  const condition = extractVariable.bind(context)(params['condition']);

  if (!condition) {
    flow[`${options.flowId}-true`] = false;
    flow[`${options.flowId}-false`] = true;

    return;
  }

  let conditionMet = false;

  switch (condition) {
    case 'equal':
      conditionMet = valueA === valueB;
    break;
    case 'grtr':
      conditionMet = valueA > valueB;
    break;
    case 'grte':
      conditionMet = valueA >= valueB;
    break;
    case 'email':
      conditionMet = `${valueA}`.match(/^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/);
    break;
    case 'date':
      conditionMet = `${valueA}`.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/) ||
        `${valueA}`.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/) ||
        `${valueA}`.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/);
    break;
    case 'number':
      conditionMet = `${valueA}`.match(/[-]?[0-9]+[,.]?[0-9]*([\/][0-9]+[,.]?[0-9]*)*/);
    break;
    case 'username':
      conditionMet = `${valueA}`.match(/^[a-z0-9_-]{3,16}$/);
    break;
    case 'password':
      conditionMet = `${valueA}`.match(/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/) ||
        `${valueA}`.match(/(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/);
    break;
    case 'url':
      conditionMet = `${valueA}`.match(/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
    break;
    case 'time':
      conditionMet = `${valueA}`.match(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/) ||
        `${valueA}`.match(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/) ||
        `${valueA}`.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) ||
        `${valueA}`.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) ||
        `${valueA}`.match(/(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/);
    break;
    case 'phone':
      conditionMet = `${valueA}`.match(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/);
    break;
    case 'exists':
      conditionMet = !!valueA;
    break;
    case 'length':
      conditionMet = valueA && `${valueA}`.length === parseInt(valueB, 10);
    break;
  };

  flow[`${options.flowId}-true`] = !!conditionMet;
  flow[`${options.flowId}-false`] = !conditionMet;
};
