import { callApi } from '../../../config';

export const handleCallApi = async (context, params) => {
  const url = params['api-url'];
  const method = params['method'] || 'GET';
  let payload;

  try {
    payload = JSON.parse(params['payload'] || '{}');
  } catch {
    payload = {};
  }

  await callApi(url, method, payload);
};
