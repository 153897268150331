import React from 'react';
import { PageWrapper } from 'wtl-components-library/web/page-wrapper/page-wrapper';
import { getBranchHTML } from '../get-branch-html';
import { WtlContextWrapper } from '../../../config';

export const PageHTML = (props) => (
  <WtlContextWrapper>
    <PageWrapper skipMetadata={true}>
      {getBranchHTML(props.schema, 0, props.options || {}, props.library || [], props.behaviours || [])}
    </PageWrapper>
  </WtlContextWrapper>
);
