/* global Stripe */
import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { getBranchHTML } from '../../core/get-branch-html';

let stripe;
const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

const WtlStripeLoading = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .35);
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 1);
  font-size: 0;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 24px;
    color: rgba(0, 50, 250, .5);
    pointer-events: none;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  }
`;

export class ContentAdvancedStripe extends React.Component {
  state = {
    isLoading: false
  };

  render() {
    const {
      productId,
      productQuantity,
      successPage,
      cancelPage,
      content,
      _customChildrenProps,
      _library,
      _behaviours,
      __key
    } = this.props;
    const { isLoading } = this.state;
    const { plugin_stripe } = _customChildrenProps;
    const extractedContent = getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);
  
    if (plugin_stripe && typeof Stripe !== 'undefined' && !stripe) {
      stripe = Stripe(plugin_stripe);
    }

    if (isLoading) {
      return (
        <Block
          {...this.props}
        >
          <WtlStripeLoading>
            <div>
              <i className="fa fa-spinner-third fa-spin" />
            </div>
          </WtlStripeLoading>
        </Block>
      );
    }
  
    return (
      <Block
        {...this.props}
      >
        <div onClick={() => {
          if (isDebug || typeof stripe === 'undefined') {
            return;
          }
  
          this.setState({ isLoading: true });
  
          stripe.redirectToCheckout({
            items: [{
              sku: productId,
              quantity: productQuantity || 1
            }],
            successUrl: `${window.location.origin}/${extractVariable(successPage) || ''}`,
            cancelUrl: `${window.location.origin}/${extractVariable(cancelPage) || ''}`
          })
          .then((result) => {
            this.setState({ isLoading: false });

            if (result.error) {
              console.info(result.error);
            }
          });
        }}>
          {extractedContent}
        </div>
      </Block>
    );
  }
}
