/**
 * #element    block
 * 
 * #props
 *  size                  0-24 span, default: 0, inline
 *  sizeMobile
 *  sizeTablet
 *  inline                render block inline (flex or block)
 *  content               body, nested block, array, or text
 *  align                 body horizontal alignment
 *  alignItemsVertically  body vertical alignment
 *  padding               body padding
 *  customStyle           CSS
 *  customWrapperElement  element to be used instead of wrapping <div>
 *  customAppearAnimation using http://michalsnik.github.io/aos/
 *  children              body alias
 *  events                directly injected React-style event listeners
 */
import React from 'react';
import { getBranchHTML } from '../core/get-branch-html';
import { getElementSize } from '../helpers/get-element-size';
import { SchemaContext } from '../core/schema-context';
import { getCachedStyling } from '../helpers/styling-cache';
import { extractVariable } from '../helpers/extract-variable';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

function rescaleHeightChanges(event, targetId) {
  if (typeof window === 'undefined' || !window._autoHeightBlocks) {
    return;
  }

  window._autoHeightBlocks = window._autoHeightBlocks.filter((id, index) => {
    if (targetId && id !== targetId) {
      return true;
    }

    const element = document.querySelector(`#${id}`);

    if (!element) {
      return false;
    }

    if (targetId || event === 'resize') {
      element.style.height = 'auto';
    }

    element.style.height = `${element.scrollHeight}px`;
    
    clearTimeout(window._autoHeightBlockTimeouts[id]);
    window._autoHeightBlockTimeouts[id] = setTimeout(() => {
      if (targetId || event === 'resize') {
        element.style.height = 'auto';
      }
      
      element.style.height = `${element.scrollHeight}px`;
    }, 1000);

    return true;
  });
}

function detectHeightChanges(id) {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window._autoHeightBlocks) {
    window._autoHeightBlocks = [];
    window._autoHeightTimeout = null;
    window._autoHeightBlockTimeouts = [];

    document.addEventListener('DOMContentLoaded', () => rescaleHeightChanges('loaded'));
    document.addEventListener('readystatechange', () => rescaleHeightChanges('readystatechange'));
    window.addEventListener('resize', () => rescaleHeightChanges('resize'));
  }

  if (!window._autoHeightBlocks.includes(id)) {
    window._autoHeightBlocks.push(id);

    setTimeout(() => {
      rescaleHeightChanges('resize', id);
    }, 1);

    if (!isDebug) {
      clearTimeout(window._autoHeightTimeout);
      window._autoHeightTimeout = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    }
  } else {
    const element = document.querySelector(`#${id}`);

    if (!isDebug && element && !element.style.height) {
      setTimeout(() => {
        rescaleHeightChanges('resize', id);
      }, 1);

      clearTimeout(window._autoHeightTimeout);
      window._autoHeightTimeout = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    }
  }
}

function detectInStyledWrapper(wrapper, rule) {
  if (!wrapper || !wrapper.componentStyle) {
    return false;
  }

  return wrapper.componentStyle.rules.join(';').indexOf(rule) !== -1;
}

export const parseStyleProps = ({
  cssBackgroundType,
  cssBackgroundColor,
  cssBackgroundImage,
  cssBackgroundRepeat,
  cssBackgroundPosition,
  cssBackgroundFit,
  cssBackgroundSharpen,
  cssBackgroundColorB,
  cssBackgroundGradientDirection,
  cssFontColor,
  cssFontStyle,
  cssFontWordWrap,
  cssFontDropShadow,
  cssBorderStyle,
  cssBorderColor,
  cssBorderThickness,
  cssBorderRadius,
  cssWidth,
  cssHeight,
  cssPositionAlign,
  cssPositionSnap,
  cssPositionOffsetY,
  cssPositionOffsetX,
  cssContentAlign,
  cssContentOverflow,
  cssContentOverflowX,
  cssContentOverflowY,
  cssPaddingTop,
  cssPaddingBottom,
  cssPaddingLeft,
  cssPaddingRight,
  cssMarginTop,
  cssMarginBottom,
  cssMarginLeft,
  cssMarginRight,
  cssTransitionAll,
  cssFontSize,
  cssFontFamily,
  cssOpacity,
  cssEffectBlur,
  cssEffectDropShadow,
  cssEffectDesaturate,
  cssEffectZoom,
  cssEffectRotate,
  cssEffectSkew,
  cssLetterSpacing,
  cssLineHeight,
  cssFontKerning,
  cssTextIndent
}) => {
  let cssStyleProps = {};
  const transforms = [];
  const filters = []

  if (cssBackgroundType) {
    if (cssBackgroundType === 'color') {
      cssStyleProps = {
        ...cssStyleProps,
        background: extractVariable(cssBackgroundColor) || '#ffffffff'
      };
    }

    if (cssBackgroundType === 'image') {
      cssStyleProps = {
        ...cssStyleProps,
        backgroundImage: `url(${extractVariable(cssBackgroundImage)})`,
        backgroundRepeat: extractVariable(cssBackgroundRepeat) || 'auto',
        backgroundPosition: extractVariable(cssBackgroundPosition) || 'auto',
        backgroundSize: extractVariable(cssBackgroundFit) || 'auto'
      };
    }

    if (cssBackgroundType === 'gradient') {
      cssStyleProps = {
        ...cssStyleProps,
        background: {
          horizontal: `linear-gradient(to right, ${extractVariable(cssBackgroundColor)} 0%, ${extractVariable(cssBackgroundColorB)} 100%)`,
          vertical: `linear-gradient(to bottom, ${extractVariable(cssBackgroundColor)} 0%, ${extractVariable(cssBackgroundColorB)} 100%)`,
          radial: `radial-gradient(ellipse at center, ${extractVariable(cssBackgroundColor)} 0%, ${extractVariable(cssBackgroundColorB)} 100%)`
        }[cssBackgroundGradientDirection || 'horizontal']
      };
    }
  }

  if (cssFontSize) {
    cssStyleProps = {
      ...cssStyleProps,
      fontSize: extractVariable(cssFontSize)
    };
  }

  if (cssFontFamily) {
    cssStyleProps = {
      ...cssStyleProps,
      fontFamily: extractVariable(cssFontFamily)
    };
  }

  if (cssFontColor) {
    cssStyleProps = { ...cssStyleProps, color: extractVariable(cssFontColor) };
  }

  if (cssFontStyle) {
    if (cssFontStyle === 'bold') {
      cssStyleProps = { ...cssStyleProps, fontWeight: 'bold' };
    }

    if (cssFontStyle === 'italiqe') {
      cssStyleProps = { ...cssStyleProps, fontStyle: 'italique' };
    }
  }

  if (cssFontWordWrap === false) {
    cssStyleProps = { ...cssStyleProps, whiteSpace: 'nowrap' };
  } else {
    cssStyleProps = { ...cssStyleProps, wordWrap: 'wrap' };
  }

  if (cssLetterSpacing) {
    cssStyleProps = { ...cssStyleProps, letterSpacing: extractVariable(cssLetterSpacing) };
  }

  if (cssLineHeight) {
    cssStyleProps = { ...cssStyleProps, lineHeight: extractVariable(cssLineHeight) };
  }

  if (cssFontKerning === false) {
    cssStyleProps = { ...cssStyleProps, fontKerning: 'none' };
  } else {
    cssStyleProps = { ...cssStyleProps, fontKerning: 'auto' };
  }

  if (cssTextIndent) {
    cssStyleProps = { ...cssStyleProps, textIndent: extractVariable(cssTextIndent) };
  }

  if (cssFontDropShadow) {
    cssStyleProps = { ...cssStyleProps, textShadow: '0px 2px 4px rgba(0, 0, 0, .3)' };
  }

  if (cssBorderStyle) {
    cssStyleProps = {
      ...cssStyleProps,
      borderStyle: extractVariable(cssBorderStyle),
      borderColor: extractVariable(cssBorderColor) || '#ffffffff',
      borderWidth: extractVariable(cssBorderThickness) || '1px',
      borderRadius: extractVariable(cssBorderRadius) || '0px'
    };
  }

  if (cssWidth) {
    cssStyleProps = {
      ...cssStyleProps,
      position: extractVariable(cssPositionSnap) || 'absolute',
      width: extractVariable(cssWidth)
    };
  }

  if (cssHeight) {
    cssStyleProps = {
      ...cssStyleProps,
      minHeight: extractVariable(cssHeight)
    };
  }

  if (cssPositionAlign) {
    cssStyleProps = {
      ...cssStyleProps,
      position: extractVariable(cssPositionSnap) || cssStyleProps.position || 'relative'
    };

    const [ alignY, alignX ] = (extractVariable(cssPositionAlign) || 'top left').split(' ');

    if (alignX === 'center') {
      transforms.push('translateX(-50%)');
    }

    if (alignX === 'right') {
      transforms.push('translateX(-100%)');
    }

    if (alignY === 'center') {
      transforms.push('translateY(-50%)');
    }

    if (alignY === 'bottom') {
      transforms.push('translateY(-100%)');
    }
  }

  if (cssWidth || cssPositionAlign) {
    cssStyleProps = {
      ...cssStyleProps,
      top: extractVariable(cssPositionOffsetY),
      left: extractVariable(cssPositionOffsetX)
    };
  }

  if (cssContentAlign) {
    const [ v, h ] = (extractVariable(cssContentAlign) || 'center center').split(' ');

    cssStyleProps = {
      ...cssStyleProps,
      alignH: h,
      alignV: v,
      textAlign: h
    };
  }

  if (cssContentOverflow || cssContentOverflowX || cssContentOverflowY) {
    const all = extractVariable(cssContentOverflow);
    const x = extractVariable(cssContentOverflowX);
    const y = extractVariable(cssContentOverflowY);

    let overflowStyle = [
      all || x ? 'hidden' : 'visible',
      all || y ? 'hidden' : 'visible'
    ].join(' ');

    if (overflowStyle === 'hidden hidden') {
      overflowStyle = 'hidden';
    }

    if (overflowStyle === 'visible visible') {
      overflowStyle = 'visible';
    }

    cssStyleProps = {
      ...cssStyleProps,
      overflow: overflowStyle
    };
  }

  if (cssPaddingTop) {
    cssStyleProps = {
      ...cssStyleProps,
      paddingTop: extractVariable(cssPaddingTop)
    };
  }

  if (cssPaddingBottom) {
    cssStyleProps = {
      ...cssStyleProps,
      paddingBottom: extractVariable(cssPaddingBottom)
    };
  }

  if (cssPaddingLeft) {
    cssStyleProps = {
      ...cssStyleProps,
      paddingLeft: extractVariable(cssPaddingLeft)
    };
  }

  if (cssPaddingRight) {
    cssStyleProps = {
      ...cssStyleProps,
      paddingRight: extractVariable(cssPaddingRight)
    };
  }

  if (cssMarginTop) {
    cssStyleProps = {
      ...cssStyleProps,
      marginTop: extractVariable(cssMarginTop)
    };
  }

  if (cssMarginBottom) {
    cssStyleProps = {
      ...cssStyleProps,
      marginBottom: extractVariable(cssMarginBottom)
    };
  }

  if (cssMarginLeft) {
    cssStyleProps = {
      ...cssStyleProps,
      marginLeft: extractVariable(cssMarginLeft)
    };
  }

  if (cssMarginRight) {
    cssStyleProps = {
      ...cssStyleProps,
      marginRight: extractVariable(cssMarginRight)
    };
  }

  if (cssTransitionAll) {
    if (cssTransitionAll === 'smooth') {
      cssStyleProps = {
        ...cssStyleProps,
        transition: 'all 1s ease'
      };
    }

    if (cssTransitionAll === 'fast') {
      cssStyleProps = {
        ...cssStyleProps,
        transition: 'all .3s ease'
      };
    }
  }

  if (cssEffectBlur) {
    filters.push(`blur(${extractVariable(cssEffectBlur)}px)`);
  }

  if (cssEffectDropShadow) {
    const value = parseFloat(extractVariable(cssEffectDropShadow));

    filters.push(`drop-shadow(${value / 5}px ${value / 5}px ${value / 10}px rgba(0, 0, 0, 0.5))`);
  }

  if (cssEffectDesaturate) {
    filters.push(`grayscale(${extractVariable(cssEffectDesaturate)}%)`);
  }

  cssStyleProps = {
    ...cssStyleProps,
    filter: filters.join(' ')
  };

  if (cssEffectZoom) {
    transforms.push(`scale(${1.0 + parseFloat(extractVariable(cssEffectZoom)) / 100})`);
  }

  if (cssEffectRotate) {
    transforms.push(`rotate(${parseFloat(extractVariable(cssEffectRotate))}deg)`);
  }

  if (cssEffectSkew) {
    transforms.push(`skew(${parseFloat(extractVariable(cssEffectSkew)) * 90 / 100}deg)`);
  }

  cssStyleProps = {
    ...cssStyleProps,
    transform: transforms.join(' ')
  };

  if (cssOpacity && isNaN(cssStyleProps.opacity)) {
    cssStyleProps = {
      ...cssStyleProps,
      opacity: parseFloat(cssOpacity) / 100
    };
  }

  return cssStyleProps;
}

export class Block extends React.Component {
  state = {
    mouseState: ''
  };

  constructor(props) {
    super(props);
  }

  getDisplayType() {
    const {
      size,
      inline
    } = this.props;

    // if (inline && !size) {
    //   return 'inline';
    // }
  
    return 'inline-block';
  }

  render() {
    const { mouseState } = this.state;
    const {
      type,
      size,
      sizeMobile,
      sizeTablet,
      content,
      inline,

      /* Deprecate soon */
      align,
      alignItemsVertically,
      padding,
      background,
      backgroundValue,
      backgroundValueB,
      backgroundValueC,
      backgroundValueD,

      /* Enable-flags for hover and active style params */
      cssStylesHover,
      cssStylesActive,

      /* New styling props */
      cssPositionAlign,
      cssPositionSnap,
      cssPositionOffsetX,
      cssPositionOffsetY,
      cssPaddingTop,
      cssPaddingBottom,
      cssPaddingLeft,
      cssPaddingRight,
      cssMarginTop,
      cssMarginBottom,
      cssMarginLeft,
      cssMarginRight,
      cssBackgroundColor,
      cssBackgroundGradientDirection,
      cssBackgroundColorB,
      cssBackgroundImage,
      cssBackgroundPosition,
      cssBackgroundRepeat,
      cssBackgroundFit,
      cssBackgroundSharpen,
      cssBorderStyle,
      cssBorderColor,
      cssBorderThickness,
      cssBorderRadius,
      cssFontColor,
      cssFontStyle,
      cssFontWordWrap,
      cssFontDropShadow,
      cssBackgroundType,
      cssWidth,
      cssHeight,
      cssAutoHeight,
      cssContentAlign,
      cssTransitionAll,
      fontSize,
      fontFamily,
      cssOpacity,
      cssEffectBlur,
      cssEffectDropShadow,
      cssEffectDesaturate,
      cssEffectZoom,
      cssEffectRotate,
      cssEffectSkew,
      cssLetterSpacing,
      cssLineHeight,
      cssFontKerning,
      cssTextIndent,
      cssContentOverflow,
      cssContentOverflowX,
      cssContentOverflowY,

      cssBackgroundTypeHover,
      cssBackgroundColorHover,
      cssBackgroundImageHover,
      cssBackgroundRepeatHover,
      cssBackgroundPositionHover,
      cssBackgroundFitHover,
      cssBackgroundSharpenHover,
      cssBackgroundColorBHover,
      cssBackgroundGradientDirectionHover,
      cssFontColorHover,
      cssFontStyleHover,
      cssFontWordWrapHover,
      cssFontDropShadowHover,
      cssBorderStyleHover,
      cssBorderColorHover,
      cssBorderThicknessHover,
      cssBorderRadiusHover,
      cssWidthHover,
      cssHeightHover,
      // cssPositionAlign,
      // cssPositionSnap,
      cssPositionOffsetYHover,
      cssPositionOffsetXHover,
      cssContentAlignHover,
      cssPaddingTopHover,
      cssPaddingBottomHover,
      cssPaddingLeftHover,
      cssPaddingRightHover,
      cssMarginTopHover,
      cssMarginBottomHover,
      cssMarginLeftHover,
      cssMarginRightHover,
      cssTransitionAllHover,
      fontSizeHover,
      fontFamilyHover,
      cssOpacityHover,
      cssEffectBlurHover,
      cssEffectDropShadowHover,
      cssEffectDesaturateHover,
      cssEffectZoomHover,
      cssEffectRotateHover,
      cssEffectSkewHover,
      cssLetterSpacingHover,
      cssLineHeightHover,
      cssFontKerningHover,
      cssTextIndentHover,
      cssContentOverflowHover,
      cssContentOverflowXHover,
      cssContentOverflowYHover,

      cssBackgroundTypeActive,
      cssBackgroundColorActive,
      cssBackgroundImageActive,
      cssBackgroundRepeatActive,
      cssBackgroundPositionActive,
      cssBackgroundFitActive,
      cssBackgroundSharpenActive,
      cssBackgroundColorBActive,
      cssBackgroundGradientDirectionActive,
      cssFontColorActive,
      cssFontStyleActive,
      cssFontWordWrapActive,
      cssFontDropShadowActive,
      cssBorderStyleActive,
      cssBorderColorActive,
      cssBorderThicknessActive,
      cssBorderRadiusActive,
      cssWidthActive,
      cssHeightActive,
      // cssPositionAlign,
      // cssPositionSnap,
      cssPositionOffsetYActive,
      cssPositionOffsetXActive,
      cssContentAlignActive,
      cssPaddingTopActive,
      cssPaddingBottomActive,
      cssPaddingLeftActive,
      cssPaddingRightActive,
      cssMarginTopActive,
      cssMarginBottomActive,
      cssMarginLeftActive,
      cssMarginRightActive,
      cssTransitionAllActive,
      fontSizeActive,
      fontFamilyActive,
      cssOpacityActive,
      cssEffectBlurActive,
      cssEffectDropShadowActive,
      cssEffectDesaturateActive,
      cssEffectZoomActive,
      cssEffectRotateActive,
      cssEffectSkewActive,
      cssLetterSpacingActive,
      cssLineHeightActive,
      cssFontKerningActive,
      cssTextIndentActive,
      cssContentOverflowActive,
      cssContentOverflowXActive,
      cssContentOverflowYActive,

      customStyle,
      customWrapperElement,
      customAppearAnimation,
      children,
      id,
      __key,
      events,
      _customElementProps,
      _customChildrenProps,
      _library,
      _behaviours,
      _groupColor,
      _locked
    } = this.props;

    if (events) {
      Object.keys(events).forEach(key => {
        events[key] = events[key].bind(this);
      });
    }

    let isSharpened = extractVariable(cssBackgroundSharpen);
    let cssStyleProps = parseStyleProps({
      cssBackgroundType: background || cssBackgroundType,
      cssBackgroundColor: backgroundValue || cssBackgroundColor,
      cssBackgroundImage: backgroundValue || cssBackgroundImage,
      cssBackgroundRepeat: backgroundValueB || cssBackgroundRepeat,
      cssBackgroundPosition: backgroundValueC || cssBackgroundPosition,
      cssBackgroundFit: backgroundValueD || cssBackgroundFit,
      cssBackgroundColorB: backgroundValueB || cssBackgroundColorB,
      cssBackgroundGradientDirection: backgroundValueC || cssBackgroundGradientDirection,
      cssBackgroundSharpen: cssBackgroundSharpen,
      cssFontColor: cssFontColor,
      cssFontStyle: cssFontStyle,
      cssFontWordWrap: cssFontWordWrap,
      cssFontDropShadow: cssFontDropShadow,
      cssBorderStyle: cssBorderStyle,
      cssBorderColor: cssBorderColor,
      cssBorderThickness: cssBorderThickness,
      cssBorderRadius: cssBorderRadius,
      cssWidth: cssWidth,
      cssHeight: cssHeight,
      cssPositionAlign: cssPositionAlign,
      cssPositionSnap: cssPositionSnap,
      cssPositionOffsetY: cssPositionOffsetY,
      cssPositionOffsetX: cssPositionOffsetX,
      cssContentAlign: cssContentAlign,
      cssPaddingTop: cssPaddingTop,
      cssPaddingBottom: cssPaddingBottom,
      cssPaddingLeft: cssPaddingLeft,
      cssPaddingRight: cssPaddingRight,
      cssMarginTop: cssMarginTop,
      cssMarginBottom: cssMarginBottom,
      cssMarginLeft: cssMarginLeft,
      cssMarginRight: cssMarginRight,
      cssTransitionAll: cssTransitionAll,
      cssFontSize: fontSize,
      cssFontFamily: fontFamily,
      cssOpacity: cssOpacity,
      cssEffectBlur: cssEffectBlur,
      cssEffectDropShadow: cssEffectDropShadow,
      cssEffectDesaturate: cssEffectDesaturate,
      cssEffectZoom: cssEffectZoom,
      cssEffectRotate: cssEffectRotate,
      cssEffectSkew: cssEffectSkew,
      cssLetterSpacing: cssLetterSpacing,
      cssLineHeight: cssLineHeight,
      cssFontKerning: cssFontKerning,
      cssTextIndent: cssTextIndent,
      cssContentOverflow: cssContentOverflow,
      cssContentOverflowX: cssContentOverflowX,
      cssContentOverflowY: cssContentOverflowY
    });

    if (cssStylesHover && mouseState === 'hover') {
      isSharpened = extractVariable(cssBackgroundSharpenHover);
      cssStyleProps = {
        ...cssStyleProps,
        ...parseStyleProps({
          cssBackgroundType: cssBackgroundTypeHover,
          cssBackgroundColor: cssBackgroundColorHover,
          cssBackgroundImage: cssBackgroundImageHover,
          cssBackgroundRepeat: cssBackgroundRepeatHover,
          cssBackgroundPosition: cssBackgroundPositionHover,
          cssBackgroundFit: cssBackgroundFitHover,
          cssBackgroundSharpen: cssBackgroundSharpenHover,
          cssBackgroundColorB: cssBackgroundColorBHover,
          cssBackgroundGradientDirection: cssBackgroundGradientDirectionHover,
          cssFontColor: cssFontColorHover,
          cssFontStyle: cssFontStyleHover,
          cssFontWordWrap: cssFontWordWrapHover,
          cssFontDropShadow: cssFontDropShadowHover,
          cssBorderStyle: cssBorderStyleHover,
          cssBorderColor: cssBorderColorHover,
          cssBorderThickness: cssBorderThicknessHover,
          cssBorderRadius: cssBorderRadiusHover,
          cssWidth: cssWidthHover || cssWidth,
          cssHeight: cssHeightHover || cssHeight,
          cssPositionAlign: cssPositionAlign,
          cssPositionSnap: cssPositionSnap,
          cssPositionOffsetY: cssPositionOffsetYHover || cssPositionOffsetY,
          cssPositionOffsetX: cssPositionOffsetXHover || cssPositionOffsetX,
          cssContentAlign: cssContentAlignHover,
          cssPaddingTop: cssPaddingTopHover,
          cssPaddingBottom: cssPaddingBottomHover,
          cssPaddingLeft: cssPaddingLeftHover,
          cssPaddingRight: cssPaddingRightHover,
          cssMarginTop: cssMarginTopHover,
          cssMarginBottom: cssMarginBottomHover,
          cssMarginLeft: cssMarginLeftHover,
          cssMarginRight: cssMarginRightHover,
          cssTransitionAll: cssTransitionAllHover,
          cssFontSize: fontSizeHover,
          cssFontFamily: fontFamilyHover,
          cssOpacity: cssOpacityHover,
          cssEffectBlur: cssEffectBlurHover,
          cssEffectDropShadow: cssEffectDropShadowHover,
          cssEffectDesaturate: cssEffectDesaturateHover,
          cssEffectZoom: cssEffectZoomHover,
          cssEffectRotate: cssEffectRotateHover,
          cssEffectSkew: cssEffectSkewHover,
          cssLetterSpacing: cssLetterSpacingHover,
          cssLineHeight: cssLineHeightHover,
          cssFontKerning: cssFontKerningHover,
          cssTextIndent: cssTextIndentHover,
          cssContentOverflow: cssContentOverflowHover,
          cssContentOverflowX: cssContentOverflowXHover,
          cssContentOverflowY: cssContentOverflowYHover
        })
      };
    }

    if (cssStylesActive && mouseState === 'active') {
      isSharpened = extractVariable(cssBackgroundSharpenActive);
      cssStyleProps = {
        ...cssStyleProps,
        ...parseStyleProps({
          cssBackgroundType: cssBackgroundTypeActive,
          cssBackgroundColor: cssBackgroundColorActive,
          cssBackgroundImage: cssBackgroundImageActive,
          cssBackgroundRepeat: cssBackgroundRepeatActive,
          cssBackgroundPosition: cssBackgroundPositionActive,
          cssBackgroundFit: cssBackgroundFitActive,
          cssBackgroundSharpen: cssBackgroundSharpenActive,
          cssBackgroundColorB: cssBackgroundColorBActive,
          cssBackgroundGradientDirection: cssBackgroundGradientDirectionActive,
          cssFontColor: cssFontColorActive,
          cssFontStyle: cssFontStyleActive,
          cssFontWordWrap: cssFontWordWrapActive,
          cssFontDropShadow: cssFontDropShadowActive,
          cssBorderStyle: cssBorderStyleActive,
          cssBorderColor: cssBorderColorActive,
          cssBorderThickness: cssBorderThicknessActive,
          cssBorderRadius: cssBorderRadiusActive,
          cssWidth: cssWidthActive,
          cssHeight: cssHeightActive,
          cssPositionAlign: cssPositionAlign,
          cssPositionSnap: cssPositionSnap,
          cssPositionOffsetY: cssPositionOffsetYActive,
          cssPositionOffsetX: cssPositionOffsetXActive,
          cssContentAlign: cssContentAlignActive,
          cssPaddingTop: cssPaddingTopActive,
          cssPaddingBottom: cssPaddingBottomActive,
          cssPaddingLeft: cssPaddingLeftActive,
          cssPaddingRight: cssPaddingRightActive,
          cssMarginTop: cssMarginTopActive,
          cssMarginBottom: cssMarginBottomActive,
          cssMarginLeft: cssMarginLeftActive,
          cssMarginRight: cssMarginRightActive,
          cssTransitionAll: cssTransitionAllActive,
          cssFontSize: fontSizeActive,
          cssFontFamily: fontFamilyActive,
          cssOpacity: cssOpacityActive,
          cssEffectBlur: cssEffectBlurActive,
          cssEffectDropShadow: cssEffectDropShadowActive,
          cssEffectDesaturate: cssEffectDesaturateActive,
          cssEffectZoom: cssEffectZoomActive,
          cssEffectRotate: cssEffectRotateActive,
          cssEffectSkew: cssEffectSkewActive,
          cssLetterSpacing: cssLetterSpacingActive,
          cssLineHeight: cssLineHeightActive,
          cssFontKerning: cssFontKerningActive,
          cssTextIndent: cssTextIndentActive,
          cssContentOverflow: cssContentOverflowActive,
          cssContentOverflowX: cssContentOverflowXActive,
          cssContentOverflowY: cssContentOverflowYActive
        })
      };
    }

    if (cssStylesActive) {
      cssStyleProps = {
        ...cssStyleProps,
        cursor: 'pointer'
      };
    }

    if (isDebug && _locked) {
      cssStyleProps = {
        ...cssStyleProps,
        pointerEvents: 'none'
      };
    }

    const {
      debugPreview
    } = _customElementProps;
    let debugSize;

    if (isDebug) {
      if (debugPreview === 'mobile') {
        debugSize = Math.min(24, sizeMobile);
      }

      if (debugPreview === 'tablet') {
        debugSize = Math.min(24, sizeTablet);
      }

      if (debugPreview === 'desktop') {
        debugSize = Math.min(24, size);
      }
    }

    const alignH = cssStyleProps.alignH || align;
    const alignV = cssStyleProps.alignV || alignItemsVertically;

    if (typeof document !== 'undefined') {
      if (_customChildrenProps.preloadFonts &&
        (cssStyleProps.fontFamily || cssStyleProps.fontFamilyActive || cssStyleProps.fontFamilyHover) &&
        (document.readyState && document.readyState !== 'complete')
      ) {
        cssStyleProps = {
          ...cssStyleProps,
          opacity: 0,
          visibility: 'hidden'
        };
      }
    }
  
    const displayType = this.getDisplayType();
    const inlinedStyles = {
      display: displayType,
      textAlign: alignH || 'left',
      padding: extractVariable(padding),
      boxSizing: 'border-box',
      alignItems: alignV || 'unset',
      position: 'relative',
      ...cssStyleProps,
      ...customStyle
    };
    const inlinedClassName = cssWidth || inline ?
      'no-inline-cls' :
      (debugSize ? getElementSize(debugSize, debugSize, debugSize) : getElementSize(size, sizeMobile, sizeTablet));
    const nestedChildren = children ? children : getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);
    const key = id || __key;
    const library = _library || [];
    let wrapper;

    if (customWrapperElement) {
      wrapper = getCachedStyling(library, extractVariable(customWrapperElement));
    } else {
      wrapper = getCachedStyling(library, 'div');
    }

    const useLegacyAlignment = _customChildrenProps.compat_align_v1;

    if (!useLegacyAlignment && (alignV || alignH)) {
      const alignableBlocks = [ 'text', 'image', 'icon', 'advanced-timer' ];

      if (alignableBlocks.includes(type)) {
        wrapper = getCachedStyling(
          library,
          extractVariable(customWrapperElement) || 'div',
          `-aiver-${type}-${alignV}-${alignH}`,
          `&>*{position: absolute; ${({
            'text': 'width: 100%;',
            'link': 'width: 100%;',
            'icon': 'width: 100%;',
            'advanced-timer': 'width: 100%;',
            'image': ''
          })[type || 'text']}; ${({
            'top': 'top: 0;',
            'center': 'top: 50%;',
            'bottom': 'top: 100%;'
          })[alignV || 'top']}${({
            'left': 'left: 0;',
            'center': 'left: 50%;',
            'right': 'left: 100%;'
          })[alignH || 'left']}transform: ${(
            [
              ({ 'top': undefined, 'center': 'translateY(-50%)', 'bottom': 'translateY(-100%)' })[alignV || 'top'],
              ({ 'left': undefined, 'center': 'translateX(-50%)', 'right': 'translateX(-100%)' })[alignH || 'left']
            ].filter(Boolean).join(' ')
          )};}`
        );
      } else {
        wrapper = getCachedStyling(
          library,
          extractVariable(customWrapperElement) || 'div',
          `-aiver-${alignV}`,
          `&>*{vertical-align:${alignV === 'center' ? 'middle' : alignV};}`
        );
      }
    }

    if (!inlinedStyles.position && !detectInStyledWrapper(wrapper, 'position')) {
      inlinedStyles.position = 'relative';
    }

    if (cssAutoHeight && nestedChildren) {
      detectHeightChanges(id);
    }

    if (!useLegacyAlignment) {
      inlinedStyles.zIndex = __key || 1;
    }

    if (isDebug && _groupColor && !inlinedStyles.cssBorderStyle) {
      inlinedStyles.borderColor = _groupColor;
    }

    const inlinedAnimations = customAppearAnimation ? {
      ['data-aos']: isDebug ? undefined : customAppearAnimation,
      ['data-aos-duration']: 1000
    } : {};

    let visibilityClasses;

    if (isDebug) {
      visibilityClasses = `
        dbg-size-${debugSize}
        ${debugSize === -1 && 'wtl-grid-hidden-xs'}
        ${debugSize === -1 && 'wtl-grid-hidden-sm'}
        ${debugSize === -1 && 'wtl-grid-hidden-md'}
        ${debugSize === -1 && 'wtl-grid-hidden-lg'}
        ${debugSize === -1 && 'wtl-grid-hidden-xl'}
      `.trim();
    } else {
      visibilityClasses = `
        ${sizeMobile === -1 ? 'wtl-grid-hidden-xs' : 'wtl-grid-visible-xs'}
        ${sizeMobile === -1 ? 'wtl-grid-hidden-sm' : 'wtl-grid-visible-sm'}
        ${sizeTablet === -1 ? 'wtl-grid-hidden-md' : 'wtl-grid-visible-md'}
        ${size === -1 ? 'wtl-grid-hidden-lg' : 'wtl-grid-visible-lg'}
        ${size === -1 ? 'wtl-grid-hidden-xl' : 'wtl-grid-visible-xl'}
      `.trim();
    }

    return React.createElement(wrapper, {
      id: id,
      key: key,
      className: [
        't-element',
        `${inlinedClassName}`,
        `k--${key}`,
        `t--${type || 'block'}`,
        `${visibilityClasses}`,
        `${isSharpened ? 'wtl-effect-sharpen' : ''}`,
      ].join(' '),
      style: inlinedStyles,
      ...inlinedAnimations,
      ...(events || {}),
      ...(_customElementProps || {}),
      ...(_customChildrenProps || {}),
      onMouseEnter: (e) => {
        if (_customElementProps && _customElementProps.onMouseEnter) {
          _customElementProps.onMouseEnter(e);
        }

        this.setState({
          mouseState: 'hover'
        });
      },
      onMouseLeave: (e) => {
        if (_customElementProps && _customElementProps.onMouseLeave) {
          _customElementProps.onMouseLeave(e);
        }

        this.setState({
          mouseState: ''
        });
      },
      onMouseDown: (e) => {
        if (_customElementProps && _customElementProps.onMouseDown) {
          _customElementProps.onMouseDown(e);
        }

        this.setState({
          mouseState: 'active'
        });
      },
      onMouseUp: (e) => {
        if (_customElementProps && _customElementProps.onMouseUp) {
          _customElementProps.onMouseUp(e);
        }

        this.setState({
          mouseState: ''
        });
      }
    }, nestedChildren);
  }
}

Block.contextType = SchemaContext;
