// & {
//   .contact-form-field {
    
//   }

//   .contact-form-field-name {
    
//   }

//   .contact-form-field-name-required {
    
//   }

//   .contact-form-field-input {
    
//   }

//   .contact-form-field-input.is-invalid {
    
//   }

//   .contact-form-field-input input {
    
//   }

//   .contact-form-field-input textarea {
    
//   }

//   .contact-form-submit {
    
//   }

//   .contact-form-submit-button {
    
//   }

//   .contact-form-submit-button button {
    
//   }

//   .contact-form-submit-spinner {
    
//   }

//   .contact-form-status-message {
    
//   }

//   .contact-form-status-message.is-pristine {
    
//   }

//   .contact-form-status-message.is-success {
    
//   }

//   .contact-form-status-message.is-error {
    
//   }

//   .contact-form-status-message.is-pending {
    
//   }

//   .contact-form-footer {
    
//   }
// }

import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { callApi } from '../../../config';

const ContactFormStatus = {
  FORM_PRISTINE: 'pristine',
  FORM_SUCCESS: 'success',
  FORM_ERROR: 'error',
  FORM_PENDING: 'pending'
};

export class ContentContactForm extends React.Component {
  state = {
    formStatus: ContactFormStatus.FORM_PRISTINE,
    formValues: {},
    invalidFields: [],
    statusMessage: null
  };

  submitForm() {
    const {
      fields,
      messageSuccess = 'Success',
      messageError = 'Error',
      emailLanguage = 'en'
    } = this.props;
    const { formValues } = this.state;

    if (!formValues) {
      return;
    }

    this.state.invalidFields = [];
    this.state.statusMessage = null;

    let isValid = true;
    let replyTo;

    [].concat(fields)
    .filter(Boolean)
    .forEach(([ name, required ]) => {
      const value = formValues[name];
      const isRequired = required === 'required' || required === 'email';

      if (isRequired && !value) {
        this.state.invalidFields.push(name);
        isValid = false;
      }

      if (required === 'email') {
        replyTo = value;
      }
    });

    if (isValid && replyTo) {
      this.state.formStatus = ContactFormStatus.FORM_PENDING;

      callApi(
        'export',
        'POST',
        {
          replyTo: replyTo,
          language: emailLanguage,
          formData: formValues
        }
      ).then(() => {
        this.state.formStatus = ContactFormStatus.FORM_SUCCESS;
        this.state.statusMessage = messageSuccess;

        this.forceUpdate();
      }).catch(() => {
        this.state.formStatus = ContactFormStatus.FORM_ERROR;
        this.state.statusMessage = messageError;

        this.forceUpdate();
      });
    }

    this.forceUpdate();
  }

  render() {
    const {
      fields,
      submitLabel,
      footerNote,
      showSpinner = true,
      useNameAsPlaceholder = false
    } = this.props;
    const { formStatus, invalidFields, statusMessage, formValues } = this.state;

    return (
      <Block {...this.props} className={`contact-form is-${formStatus}`}>
        {[].concat(fields)
        .filter(Boolean)
        .map(([ name, required, inputType ]) => (
          <div className={[
            'contact-form-field',
            `contact-form-field-${invalidFields.indexOf(name) !== -1 ? 'is-invalid' : (formValues[name] ? 'is-valid' : '')}`
          ].join(' ')} style={{ display: 'flex' }}>
            {!useNameAsPlaceholder && <div className="contact-form-field-name">
              {extractVariable(name)}
              {(required === 'required' || required === 'email') && (
                <div
                  className="contact-form-field-name-required"
                  style={{ display: 'inline-block' }}
                >
                  *
                </div>
              )}
            </div>}
            <div className={`contact-form-field-input ${invalidFields.indexOf(name) !== -1 ? 'is-invalid' : (formValues[name] ? 'is-valid' : '')}`}>
              {
                (!inputType || inputType === 'text') && (
                  <input
                    type="text"
                    name={`contact-form-field-${name}`}
                    onChange={(e) => {
                      this.state.formValues[name] = e.target.value;
                      this.forceUpdate();
                    }}
                    placeholder={useNameAsPlaceholder ? extractVariable(name) : undefined}
                  ></input>
                )
              }
              {
                inputType === 'textarea' && (
                  <textarea
                    name={`contact-form-field-${name}`}
                    onChange={(e) => {
                      this.state.formValues[name] = e.target.value;
                      this.forceUpdate();
                    }}
                    placeholder={useNameAsPlaceholder ? extractVariable(name) : undefined}
                  ></textarea>
                )
              }
            </div>
          </div>
        ))}
        <div className="contact-form-submit">
          <div className="contact-form-submit-button">
            <button onClick={() => this.submitForm()} disabled={formStatus === ContactFormStatus.FORM_PENDING}>
              {formStatus === ContactFormStatus.FORM_PENDING && showSpinner !== 'false' && (
                <i className="contact-form-submit-spinner far fa-spinner-third fa-spin" />
              )}
              {extractVariable(submitLabel) || 'submit'}
            </button>
          </div>
        </div>
        {statusMessage && (
          <div className={`contact-form-status-message is-${formStatus}`}>
            {extractVariable(statusMessage)}
          </div>
        )}
        {footerNote && (
          <div className="contact-form-footer">
            {extractVariable(footerNote)}
          </div>
        )}
      </Block>
    );
  }
}
