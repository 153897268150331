import React from 'react';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';

const isValidUrl = (url) => url && url.match(/^https?\:\/\/(www\.)?youtube.com\/(watch|embed)/);

const validateEmbedVideo = (url) => {
  if (url.match(/^https?\:\/\/(www\.)?youtube.com\/embed/)) {
    return url;
  }

  return url.replace('/watch?v=', '/embed/');
}

export const ContentAdvancedYouTube = (props) => {
  const {
    url,
    startAt = 0,
    showControls
  } = props;

  return (
    <Block {...props}>
      {
        isValidUrl(extractVariable(url)) ? (
          <iframe
            width="560"
            height="315"
            src={`${validateEmbedVideo(extractVariable(url))}?start=${extractVariable(startAt) || 0}&amp;controls=${showControls === false ? '0' : '1'}`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : (
          <>
            <i className="fab fa-youtube"></i> YouTube video
          </>
        )
      }
    </Block>
  );
};