import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { getBranchHTML } from '../../core/get-branch-html';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

const RootWrapperShadow = styled.div`
  position: absolute;
  top: 0;
  ${p => p.align === 'left' ? 'left: 0' : 'right: 0'};
  width: ${p => `calc((100% - ${p.gridSize}px) * 0.5)`};
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  pointer-events: none;
  z-index: 1;
`;

const RootWrapper = styled.div`
  position: relative;
  width: 100% !important;
  max-width: ${p => p.gridSize || '960'}px !important;
  min-height: ${p => p.height} !important;
  margin: 0 auto;
`;

export const ContentRoot = (props) => {
  const {
    gridSize = 980,
    content,
    _customChildrenProps,
    _customElementProps,
    _library,
    _behaviours,
    __key,

    /* NOTE Root wrapper inherits some of style properties from the centered column */
    cssHeight,
    cssBackgroundType,
    cssBackgroundColor,
    cssBackgroundImage,
    cssBackgroundRepeat,
    cssBackgroundPosition,
    cssBackgroundFit,
    cssBackgroundColorB,
    cssBackgroundGradientDirection,
    cssTransitionAll,
    cssEffectBlur,
    cssEffectDesaturate,
    cssEffectSkew
  } = props;
  const nestedChildren = getBranchHTML(content, __key + 1, _customChildrenProps, _library, _behaviours);

  return (
    <Block
      id={`${props.id}-root`}
      size={24}
      cssHeight={cssHeight || '680px'}
      cssBackgroundType={cssBackgroundType}
      cssBackgroundColor={cssBackgroundColor}
      cssBackgroundImage={cssBackgroundImage}
      cssBackgroundRepeat={cssBackgroundRepeat}
      cssBackgroundPosition={cssBackgroundPosition}
      cssBackgroundFit={cssBackgroundFit}
      cssBackgroundColorB={cssBackgroundColorB}
      cssBackgroundGradientDirection={cssBackgroundGradientDirection}
      cssTransitionAll={cssTransitionAll}
      cssEffectBlur={cssEffectBlur}
      cssEffectDesaturate={cssEffectDesaturate}
      cssEffectSkew={cssEffectSkew}
      _customChildrenProps={_customChildrenProps}
      _customElementProps={_customElementProps}
      _library={_library}
      _behaviours={_behaviours}
    >
      {isDebug && <RootWrapperShadow align="left" gridSize={gridSize} />}
      {isDebug && <RootWrapperShadow align="right" gridSize={gridSize} />}
      <RootWrapper
        className="t-element"
        gridSize={gridSize}
        height={cssHeight || '680px'}
        {...props}
      >
        {nestedChildren}
      </RootWrapper>
    </Block>
  );
};
